<p-dialog header="Study Partner Request" [(visible)]="displayRequestModal" [modal]="true" style="max-width: '90vw'" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <p class="p-m-0 mb-2"><b> Course: </b> {{requestBody.course}}</p>
    <p class="p-m-0 mb-2"><b> Gender: </b> {{requestBody.gender}}</p>
    <p class="p-m-0 mb-2"><b> Country:</b> {{requestBody.country}}</p>
    <p class="p-m-0 mb-2"><b> Under or Postgraduate: </b> {{requestBody.postgraduate}}</p>
    <p class="p-m-0 mb-2"><b> Study Level: </b> {{requestBody.studylevel}}</p>
    <p class="p-m-0 mb-2"><b> Exam Date: </b> {{requestBody.examdate}}</p>
    <p class="p-m-0 mb-2"><b> Contact: </b> {{requestBody.contact}}</p>
        <ng-template pTemplate="footer">
        <!-- <p-button icon="pi pi-check" (click)="displayRequestModal=false" label="Ok" styleClass="p-button-text"></p-button> -->
        </ng-template>
</p-dialog>
<p-dialog header="Admin Notification" [(visible)]="displayAdminRequestModal" [modal]="true" style="max-width: '90vw'" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <p class="p-m-0 mb-2"><b> Date: </b> {{AdminRequest?.date}}</p>
    <p class="p-m-0 mb-2"><b> Message: </b> {{AdminRequest?.request}}</p>
        <ng-template pTemplate="footer">
        <!-- <p-button icon="pi pi-check" (click)="displayRequestModal=false" label="Ok" styleClass="p-button-text"></p-button> -->
        </ng-template>
</p-dialog>

<div class="eup-db-root" >
  <div class="eup-db-parent">
    <div class="eup-db-subparent">
      <div class="eup-db-grid-wrapper">

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 p-0">
              <div class="eup-db-top-bar-wrapper">
                <div class="eup-db-top-bar">
                  <div class="eup-db-top-bar-heading">
                    <span id="eup-navbar-heading-wrapper">
                    <h4 id="eup-navbar-heading"></h4></span>
                    <i class="fa fa-bars fa-2x ml-3 hamburger" style="color: #FFFFFF;" (click)="hamburgerclicked()"></i>
                  </div>

                  <div class="eup-db-top-bar-user-profile-icon">
                    <div class="icon_notification mr-2">
                      <div *ngIf="isdemo" (click)="getPremiumMembership()" class="premium_membership getPremiumMembership">Get Premium Membership</div>
                      <div *ngIf="!isdemo && !noSubscription" class="study_partner dropdown-toggle partner_request_heading" data-toggle="dropdown" (click)="checkStudypartnerRequest()">Request {{(isMobView == false)?'a ':''}}Study Partner</div>
                      <div class="dropdown-menu" id="drop-down-menu" aria-labelledby="dropdownMenuButton" (click)="openDropdown($event)">
                        <div class="dropdown_header">
                          <span>Request a Study Partner</span>
                        </div>
                        <div class="dropdown-item">
                          <div class="dropdown_single_item">
                            <div class="top_content_wrapper">
                              <div class="notificationalert" *ngIf="isRequestPresent === true">You have already submitted a request. <br> You cannot submit another request.</div>
                              <div class="p-field">
                                <label for="course" class="mr-1">Course:* </label>
                                <p-dropdown placeholder="Select Course" [options]="allCourses" [(ngModel)]="course" ></p-dropdown>
                                <!-- <input id="course" type="username"  pInputText [(ngModel)]="course" /> -->
                            </div>
                            <div class="p-field">
                              <label for="gender" class="mr-1">Gender:* </label>
                              <p-dropdown placeholder="Select Gender" [options]="allGenders" [(ngModel)]="gender" ></p-dropdown>
                              <!-- <input id="gender" type="username" pInputText [(ngModel)]="gender" /> -->
                          </div>
                          <div class="p-field">
                            <label for="country" class="mr-1">Country:* </label>
                            <p-dropdown placeholder="Select Country" placeholder="Choose Country" [options]="country_list" [(ngModel)]="country" ></p-dropdown>
                            <!-- <input id="country" type="text" pInputText [(ngModel)]="country" /> -->
                        </div>
                        <div class="p-field">
                          <label for="graduate" class="mr-1">Under / Postgraduate:* </label>
                          <p-dropdown placeholder="Select" [options]="Graduations" [(ngModel)]="postgraduate" ></p-dropdown>
                          <!-- <input id="graduate" type="text" pInputText [(ngModel)]="postgraduate" /> -->
                        </div>
                        <div class="p-field">
                          <label for="study" class="mr-1">Study Level:* </label>
                          <p-dropdown placeholder="Select Study Level" [options]="allStudyLevel" [(ngModel)]="studyLevel" ></p-dropdown>
                          <!-- <input id="study" type="text" pInputText [(ngModel)]="studyLevel" /> -->
                        </div>
                        <div class="p-field">
                          <label for="exam_date" class="mr-1">Exam Date:* </label>
                          <input id="exam_date" placeholder="Select Date" type="date" pInputText [(ngModel)]="examDate" />
                        </div>
                        <div class="p-field">
                          <label for="contact" class="mr-1">Contact:* </label>
                          <input id="contact" type="text" pInputText [(ngModel)]="contact" />
                        </div>
                        <div id="fill_text" class="notificationalert">  </div>

                            <!-- <textarea [rows]="4" [cols]="30" [(ngModel)]="studyPartnerRequest" pInputTextarea ></textarea> -->
                          </div>
                          <div class="bottom_btns">
                            <button class="btn interested_btns mt-3" [disabled]="isRequestPresent === true" (click)="RaisePartnerRequest()">Raise a Study Partner Request</button>
                          </div>
                          </div>
                        </div>
                      </div>
                      </div>


                    <div class="icon_notification setNotificationWidth">
                      <i class="pi pi-users p-mr-4 dropdown-toggle" data-toggle="dropdown" pBadge [value]="(AllStudyPartnerRequests && AllStudyPartnerRequests.length>0)?AllStudyPartnerRequests.length:0" ></i>
                      <div class="dropdown-menu mt-2" (click)="openDropdown($event)"  aria-labelledby="dropdownMenuButton">
                        <div class="dropdown_header">
                          <span class="getborder" >Study Partner Request</span>
                        </div>
                        <div class="dropdown-item mt-2" >
                          <div class="dropdown_single_item mb-3" *ngFor="let request of AllStudyPartnerRequests">
                            <div class="top_content_wrapper">
                            <div class="usericon"> <i class="pi pi-user"></i> </div>
                            <div class="user_message_wrapper ml-1">
                              <div class="user_name">
                                {{request.user_name}}
                              </div>
                              <div class="message_title">Course</div>
                              <div class="message_content mb-3" style="max-width: 300px;"> {{request.request.course}} </div>
                            </div>
                          </div>
                          <div class="bottom_btns">
                            <button class="btn interested_btns" (click)="displayRequest(request)" > Interested </button>
                            <button class="btn uninterested_btns" (click)="deleteRequest(request.req_id, request.user_id)" *ngIf="userId == request.user_id">Delete Request</button>
                          </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="icon_notification setNotificationWidth" >
                      <i class="pi pi-bell p-mr-4 p-mt-1 p-text-secondary dropdown-toggle" data-toggle="dropdown" pBadge style="font-size: 1.5rem; color: #FFFFFF" [value]="(allAdminNotifications && allAdminNotifications.length>0)?allAdminNotifications.length: 0"></i>

                      <div class="dropdown-menu mt-2" (click)="openDropdown($event)"  aria-labelledby="dropdownMenuButton">
                        <div class="dropdown_header">
                          <span class="getborder" (click)="isAdminNotification = true">Admin Notification</span>
                        </div>

                        <div class="dropdown-item mt-2"  >
                          <div class="dropdown_single_item mb-3" *ngFor="let request of allAdminNotifications">
                            <div class="top_content_wrapper">
                            <div class="usericon"> <i class="pi pi-user"></i> </div>
                            <div class="user_message_wrapper ml-1">
                              <div class="user_name">
                                {{request.user_name}}
                              </div>
                              <div class="message_title">Message</div>
                              <div class="message_content mb-3" style="max-width: 300px; word-wrap: break-word; white-space: initial !important;"> {{request.request}} </div>
                            </div>
                          </div>
                          <div class="bottom_btns">
                            <button class="btn interested_btns" (click)="displayAdminNotification(request)" > See Full Screen </button>
                            <!-- <button class="btn uninterested_btns" (click)="deleteAdminRequest(request)">Delete</button> -->
                          </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    <div class="eup_username" id="eup_username"> {{(isMobView == false)?userName:''}}</div>
                    <div class="dropdown">
                      <i class="fa fa-user-circle-o" data-toggle="dropdown" ></i>
                      <ol class="dropdown-menu">
                        <li class="pt-3 h6" (click)="gotoProfile(true)" ><a  ><i class="fa fa-user-circle-o"></i> Profile</a></li>
                        <li class="pt-3 h6" routerLink="/dashboard/courses" ><a ><i class="fa fa-sticky-note"></i> Courses</a></li>
                        <li class="pt-3 h6" (click)="logout()"><a><i class="fa fa-sign-out"></i>{{(isdemo)?'Exit Demo':'Logout'}}</a></li>
                      </ol>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 p-0">
              <div class="eup-db-content-wrapper">
                <div class="eup-db-content-left-parent" [ngClass]="{'flex-20': isopen == true && (isMobView == true || isMobView == false) , 'flex-0': isopen == false && isMobView == true , 'flex-5': isopen == false && isMobView == false }" id="sidebar">
                 <div class="eup-db-side-bar-logo">
                  <a href="https://elhusseinyusmleprep.com/"><img src="../../assets/images/logo-edit.png" alt=""></a>
                 </div>
                  <div class="eup-db-side-bar-navigation-wrapper">
                    <ul class="eup-db-side-bar-navigation">
                      <div *ngFor="let item of menuItems; let i = index;">
                        <li *ngIf="item.children.length <= 0" (click)="(item.demo ? displayDemoModal() : '')" class="eup-db-side-bar-nav-item" [routerLink]="((item.demo) ? null : item.link)" [routerLinkActive]="((item.demo) ? '' : 'active')" [routerLinkActiveOptions]="{exact: true}">
                          <i class="eup-db-side-bar-link-icon {{item.icon}}"></i>
                          <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">{{item.title}}</span>
                          <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">{{item.title}}</span>
                        </li>
                        <li *ngIf="item.children.length > 0" class="eup-db-side-bar-nav-item-children" (click)="openChildMenu(i)">
                          <div style="display: flex; align-items: center; justify-content: space-between;">
                            <div>
                              <i class="eup-db-side-bar-link-icon {{item.icon}}"></i>
                              <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">{{item.title}}</span>
                              <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">{{item.title}}</span>
                            </div>
                            <div style="margin-top: 0.25rem; padding-right: 0.25rem;">
                              <i style="margin-right: 1rem;" class="pi pi-chevron-down"></i>
                            </div>
                          </div>
                          <div *ngIf="item.opened">
                            <div *ngFor="let child of item.children" style="margin-top: 0.5rem;">
                              <li class="eup-db-side-bar-nav-item" [ngStyle]="{'margin-left': isopen ? '10px' : '0'}" [@fadeInOut] [routerLink]="child.link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <i class="eup-db-side-bar-link-icon {{child.icon}}"></i>
                                <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">{{child.title}}</span>
                                <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">{{child.title}}</span>
                              </li>
                            </div>
                          </div>
                        </li>
                      </div>

                      <li class="eup-db-side-bar-nav-item"  (click)="logout()">
                        <i class="fa fa-sign-out eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-2  pl-1']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">{{(isdemo)?'Exit Demo':'Logout'}}</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">{{(isdemo)?'Exit Demo':'Logout'}}</span>
                      </li>

                      <!-- Get Premium Memebership -->
                      <li class="eup-db-side-bar-nav-item" *ngIf="isdemo"  (click)="getPremiumMembership()">
                        <i class="pi pi-briefcase eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-2  pl-1']"></i>
                        <span class="eup-db-side-bar-link-text getPremiumMembership"  *ngIf="isopen == true && isMobView == false">Get Premium Membership</span>
                        <span class="eup-db-side-bar-link-text getPremiumMembership" *ngIf="isMobView == true">Get Premium Membership</span>
                      </li>


                      <!-- <li class="eup-db-side-bar-nav-item" id="welcome" (click)="welcome = true ; courses = false; previous_test = false; calculator=false; generate_quiz = false; ischat=false; Overall_Performance= false; Graphs = false; Notes = false; Account = false; notebook=false; search = false; flashcard = false; reset = false" [ngClass]="[welcome == true ? 'eup_highlight_sidebar' : 'noClass']" routerLink="/dashboard/welcome">
                        <i class="fa fa-dashboard eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-1 ']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">Welcome</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">Welcome</span>
                      </li>
                      <li class="eup-db-side-bar-nav-item" id="courses" (click)="welcome = false ; courses = true; previous_test = false; calculator=false; generate_quiz = false; ischat=false; Overall_Performance= false; Graphs = false; Notes = false; Account = false; notebook=false; search = false; flashcard = false; reset = false" [ngClass]="[courses == true ? 'eup_highlight_sidebar' : 'noClass']" routerLink="/dashboard/courses"  >
                        <i class="fa fa-sticky-note eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-1 ']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">Courses</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">Courses</span>
                      </li>
                      <li class="eup-db-side-bar-nav-item" id="prevTest" (click)="welcome = false ; courses = false; previous_test = true; calculator=false; generate_quiz = false; ischat=false; Overall_Performance= false; Graphs = false; Notes = false; Account = false; notebook=false; search = false; flashcard = false; reset = false" [ngClass]="[previous_test == true ? 'eup_highlight_sidebar' : 'noClass']" routerLink="/dashboard/previoustest">
                        <i class="fa fa-step-backward eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-1 ']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">Previous Test</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">Previous Test</span>
                      </li>
                      <li class="eup-db-side-bar-nav-item" id="generateQuiz" (click)="welcome = false ; courses = false; previous_test = false; calculator=false; generate_quiz = true; Overall_Performance= false; Graphs = false; Notes = false; Account = false; notebook=false; search = false; flashcard = false; reset = false" [ngClass]="[generate_quiz == true ? 'eup_highlight_sidebar' : 'noClass']" routerLink="/dashboard/generatequiz">
                        <i class="fa fa-question eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-1 ']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">Generate Quiz</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">Generate Quiz</span>
                      </li>
                      <li id="overallPerformance" id="performance" (click)="welcome = false ; courses = false; previous_test = false; generate_quiz = false; ischat=false;  Overall_Performance= false; Graphs = false; calculator=false; Notes = false; Account = false; notebook=false; search = false; flashcard = false; reset = false" class="eup-db-side-bar-nav-item" [ngClass]="[Overall_Performance == true ? 'eup_highlight_sidebar' : 'noClass']" (click)="openPerformance()">
                        <i class="pi pi-chart-line eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-1 ']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">Overall Performance</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">Overall Performance</span>
                        <div *ngIf="showPerformance" [@fadeInOut] class="performance_link mb-3" routerLink="/dashboard/overallperformance" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fa fa-table pr-2" >
                            </i>
                            Overall
                        </div>
                        <div *ngIf="showPerformance" [@fadeInOut] class="performance_link mb-3" [routerLink]="['/dashboard/graphPerformance']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fa fa-line-chart pr-2" >
                            </i>
                            Peer Ranking
                        </div>
                      </li>
                      <li class="eup-db-side-bar-nav-item" id="score" (click)="welcome = false ; courses = false; previous_test = false; generate_quiz = false; ischat=false; Overall_Performance= false; Graphs = false; calculator=true; Notes = false; Account = false; notebook=false; search = false; flashcard = false; reset = false" [ngClass]="[calculator == true ? 'eup_highlight_sidebar' : 'noClass']" routerLink="/dashboard/scorecalculator">
                        <i class="fa fa-calculator eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-1 ']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">Score Calculator</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">Score Calculator</span>
                      </li>
                      <li class="eup-db-side-bar-nav-item" id="graphs" (click)="welcome = false ; courses = false; previous_test = false; calculator=false; generate_quiz = false; ischat=false; Overall_Performance= false; Graphs = true; Notes = false; Account = false; notebook=false; search = false; flashcard = false; reset = false" [ngClass]="[Graphs == true ? 'eup_highlight_sidebar' : 'noClass']" routerLink="/dashboard/overallperformancechart">
                        <i class="pi pi-chart-bar eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-1 ']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">Graphs</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">Graphs</span>
                      </li>
                      <li class="eup-db-side-bar-nav-item" id="notes" (click)="welcome = false ; courses = false; previous_test = false; calculator=false; generate_quiz = false; ischat=false; Overall_Performance= false; Graphs = false; Notes = true; Account = false; notebook=false; search = false; flashcard = false; reset = false; displayDemoModal();" [ngClass]="[Notes == true ? 'eup_highlight_sidebar' : 'noClass']" [routerLink]="(!isdemo ? '/dashboard/notes' : null)">
                        <i class="pi pi-file eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-1 ']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">Notes</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">Notes</span>
                      </li>
                      <li class="eup-db-side-bar-nav-item" id="search" (click)="welcome = false ; courses = false; previous_test = false; calculator=false; generate_quiz = false; ischat=false; Overall_Performance= false; Graphs = false; Notes = false; notebook=false; Account = false; search = true; flashcard = false; reset = false; displayDemoModal();" [ngClass]="[search== true ? 'eup_highlight_sidebar' : 'noClass']"  [routerLink]="(!isdemo ? '/dashboard/search' : null)">
                        <i class="pi pi-search eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-1 ']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">Search</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">Search</span>
                      </li>
                      <li class="eup-db-side-bar-nav-item" id="flashcard" (click)="welcome = false ; courses = false; previous_test = false; calculator=false; generate_quiz = false; ischat=false; Overall_Performance= false; Graphs = false; Notes = false; notebook=false; Account = false; search = false; flashcard = true; reset = false; displayDemoModal();" [ngClass]="[flashcard== true ? 'eup_highlight_sidebar' : 'noClass']" [routerLink]="(!isdemo ? '/dashboard/flashcard' : null)">
                        <i class="fa fa-bolt eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-1 ']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">Flashcards</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">Flashcards</span>
                      </li>
                      <li class="eup-db-side-bar-nav-item" id="notes" (click)="welcome = false ; courses = false; previous_test = false; calculator=false; generate_quiz = false; ischat=false; Overall_Performance= false; Graphs = false; Notes = false; notebook=true; Account = false; search = false; flashcard = false; reset = false; displayDemoModal();" [ngClass]="[notebook== true ? 'eup_highlight_sidebar' : 'noClass']" [routerLink]="(!isdemo ? '/dashboard/notebook' : null)">
                        <i class="pi pi-book eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-1 ']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">My Notebook</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">My Notebook</span>
                      </li>
                      <li class="eup-db-side-bar-nav-item" id="notes" (click)="welcome = false ; courses = false; previous_test = false; calculator=false; generate_quiz = false; ischat=true; Overall_Performance= false; Graphs = false; Notes = false; Account = false; notebook=false; search = false; flashcard = false; reset = false; displayDemoModal();" [ngClass]="[ischat == true ? 'eup_highlight_sidebar' : 'noClass']" [routerLink]="(!isdemo ? '/dashboard/chat' : null)">
                        <i class="pi pi-comment eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-1 ']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">Ask Your Instructor</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">Ask Your Instructor</span>
                      </li>
                      <li class="eup-db-side-bar-nav-item" id="notes" (click)="welcome = false ; courses = false; previous_test = false; calculator=false; generate_quiz = false; ischat=false; Overall_Performance= false; Graphs = false; Notes = false; Account = true; notebook=false; search = false; flashcard = false; reset = false" [ngClass]="[Account == true ? 'eup_highlight_sidebar' : 'noClass']" routerLink="/dashboard/profile/0">
                        <i class="pi pi-credit-card eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-1 ']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">Renewal</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">Renewal</span>
                      </li>
                      <li class="eup-db-side-bar-nav-item" id="notes" (click)="welcome = false ; courses = false; previous_test = false; calculator=false; generate_quiz = false; ischat=false; Overall_Performance= false; Graphs = false; Notes = false; Account = false; notebook=false; search = false; flashcard = false; reset = true" [ngClass]="[reset == true ? 'eup_highlight_sidebar' : 'noClass']" routerLink="/dashboard/reset">
                        <i class="pi pi-undo eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : 'mb-1 ']"></i>
                        <span class="eup-db-side-bar-link-text" *ngIf="isopen == true && isMobView == false">Reset</span>
                        <span class="eup-db-side-bar-link-text" *ngIf="isMobView == true">Reset</span>
                      </li> -->
                    </ul>
                  </div>
                  <div *ngIf="isopen == true">
                    <div *ngFor="let membership of memberships" class="membership">
                      <span style="font-size: 12px; text-align: center; width: 60%; margin-bottom: 10px;">{{membership.Title}}</span>
                      <h4>Expiration Date</h4>
                      <span>{{membership.EndDate | date: 'MMM dd, yyyy'}}</span>
                      <button class="membership_button" *ngIf="membership.Status == 'expired'" routerLink="/dashboard/profile/0">Renew</button>
                    </div>
                  </div>
                </div>
                <div class="eup-db-content-right-parent" [ngClass]="[isopen == true && isMobView == true ? 'flex-80' : '']" [ngClass]="[isopen == true && isMobView == false ? 'flex-80' : '']" [ngClass]="[isopen == false && isMobView == true ? 'flex-100' : '']" [ngClass]="[isopen == false && isMobView == false ? 'flex-95' : '']"  id="rightContent">
                  <router-outlet></router-outlet>
                </div>
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>
  </div>
</div>

<p-dialog header="Premium Feature" [(visible)]="displayDemo" [modal]="true" style="max-width: '400px'" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; margin: 0 auto; margin-top: 15px; width: 75%;">
      <img src="../../assets/images/eup_logo.png" width="200">
      <span class="demoFeature">This feature is only available for premium users</span>
    </div>
</p-dialog>