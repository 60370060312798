import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AdminService } from '../shared/services/admin.service';
import { GlobalService } from '../shared/services/global.service';
import { AutoLogout } from '../models/globals';

@Component({
  selector: 'app-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.scss']
})
export class AdmindashboardComponent implements OnInit {
  isopen = false;
  userName: any;
  visibleSidebar2 = false;
  allNotifications: any;
  newNotification: any;

  constructor(private adminService: AdminService ,private globalService: GlobalService ,private route:ActivatedRoute,private router:Router) {
    // router.navigate(['/admin/home'])
  }

  ngOnInit(): void {
    var userName = localStorage.getItem("UserName");
    // document.getElementById("app-Title").innerHTML = userName;


    this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email'))
    if(localStorage.getItem('UserName')){
      this.userName = localStorage.getItem('UserName');
    }
    // @ts-ignore
    document.getElementById('eup-navbar-heading').innerText = 'Dashboard';
    if(!localStorage.getItem("token")){
      this.router.navigate(['/']);
    }
    
    this.getAdminNotifications()
  }

  logout(): void {
    sessionStorage.removeItem('selectedCourse');
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }


  hamburgerclicked():void {
    this.isopen = !this.isopen;
      // @ts-ignore
      // $("#sidebar").toggle("slow");
    }

    getAdminNotifications(): any{
      this.adminService.getAdminNotifications().subscribe((res: any) => {
        this.allNotifications = res.res;
    })
  }

    sendNotification(){
    var data = {
      adminId: JSON.parse(localStorage.getItem("Id")),
      userName: localStorage.getItem("UserName"),
      request: this.newNotification
    }
   
    this.adminService.sendAdminNotifications(data).subscribe((res: any) => {
      this.newNotification = '';
      this.getAdminNotifications()
  },
  (        err: any) => {
    AutoLogout(err);
  }
)
  
    }

    deleteAdminNotification(notificationId: any){
      
      this.adminService.deleteSpecificAdminNotification(notificationId).subscribe((res: any) => {
        this.getAdminNotifications()
    },
    (        err: any) => {
      AutoLogout(err);
    }
  )
    
      }
    

}
