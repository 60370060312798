import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  user=  {
    ID: localStorage.getItem("Id"),
    user_email: localStorage.getItem("email"),
  }
  code = ''
  message = ''
  token1 = localStorage.getItem("token")
  termsModel = false;
  loader = false;
  ifAdmin = false;

  constructor(private authService: AuthService , private route:ActivatedRoute,private router:Router) { }

  ngOnInit(): void {
    this.getUserCourses()
    if(!localStorage.getItem("token")){
    }
    else{
      this.router.navigate(['/dashboard/welcome']);
    }
    if(!localStorage.getItem("email")){
      this.router.navigate(['/']);
    }
  }

  getUserCourses() {
    this.authService.getUserCourses().subscribe((res: any) => {
      localStorage.setItem('subscriptions', JSON.stringify(res.returnCourses))
    })
  }
  
token: any;
  authentication(): void{
    this.loader = true;
    this.authService.authentication(this.user , this.code).subscribe((data: any) => {
      this.loader = false;
      if(data.token && data.status){

      }
      if(data.data && data.data.status == true){
        this.token = data.data.token;
        this.ifAdmin = data.data.ifAdmin
        this.termsModel = true;
    }
    else{
      this.message = 'Code Verification Failed'
    }
    });
  }

  gotoHomeScreen(){
    localStorage.setItem('token', this.token)
    if(this.ifAdmin){
      let subscriptions = [
        {
          courseID: 115014,
          courseName: 'USMLE Step 1',
          courseQuestions: 0
        },
        {
          courseID: 115015,
          courseName: 'USMLE Step 2 CK',
          courseQuestions: 0
        },
        {
          courseID: 136132,
          courseName: 'Free Trial',
          courseQuestions: 0
        }
      ]
      localStorage.setItem('subscriptions', JSON.stringify(subscriptions))
      this.router.navigate(['/admin/home']);
    }else{
    this.router.navigate(['/dashboard/welcome']);
  }
  }
}
