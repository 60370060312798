<app-overlay-loader *ngIf="quizloader"></app-overlay-loader>


<div class="csq-root">
  <!-- TIME IS UP MODAL BOX -->
  <p-dialog header="Time Notification" [(visible)]="displayModal1" [modal]="true" [closable]="false"
    [style]="{width: '330px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <p class="p-m-0">Your Time is up! Kindly return to Home Screen.</p>
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="onEndBlock()" label="Go Back" class="p-button-text"></p-button>
      <!-- <p-button icon="pi pi-times" (click)="displayModal1=false" label="No"></p-button> -->
    </ng-template>
  </p-dialog>

  <p-dialog header="Confirmation" [(visible)]="displayAlert" [modal]="true" [closable]="false"
    [style]="{width: '330px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <p class="p-m-0">Do you Want to End This Quiz?</p>
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="onEndBlock()" label="Yes" class="p-button-text"></p-button>
      <p-button icon="pi pi-times" (click)="displayAlert=false" label="No"></p-button>
    </ng-template>
  </p-dialog>
  <p-dialog header="Confirmation" [(visible)]="displaysuspendAlert" [modal]="true" [closable]="false"
    [style]="{width: '330px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <p class="p-m-0">Do you Want to Suspend This Quiz?</p>
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="onEndBlock()" label="Yes" class="p-button-text"></p-button>
      <p-button icon="pi pi-times" (click)="displaysuspendAlert=false" label="No"></p-button>
    </ng-template>
  </p-dialog>
  <p-confirmDialog *ngIf="!shownotebookdialog" [style]="{width: '330px'}" [baseZIndex]="10000"></p-confirmDialog>

  <!-- TIME IS UP MODEL BOX -->
  <!-- <p-button (click)="confirmOmission()" icon="pi pi-check" label="Confirm"></p-button> -->
  <div class="csq-parent" toggleFullscreen id="quiz-module-root">
    <div class="csq-subparent">
      <div class="csq-grid-wrapper">
        <div class="p-grid p-p-0 csq-grid" id="csq-custom-quiz-screen">

          <div class="csq-left-parent csq-sm-display-none">
            <div class="csq-question-side-navbar-wrapper">
              <div class="" [ngClass]="displaySidebar?'csq-flex-10':'csq-flex-0'">
                <div class="csq-sidenav pb-4" id="sidenav"
                  [ngClass]="isBlackTheme?'csq-black-theme':isLightTheme?'csq-light-theme':'csq-default-theme'">
                  <div class="sidebar-question-counter" *ngFor="let option of allQuestions; let i = index"
                    (click)="getQuestion(i)">
                    <div class="counter-div counter-div-ipad"
                      [style.backgroundColor]="currentQuestionIndex===i?'#5590cc':i%2?'#e3e3e3':'#fff'">
                      <span class="dot dotSpan" id="{{i}}" *ngIf="showDot(i)">
                        &#183;
                      </span>
                      <span class="dot dotSpan" *ngIf="!showDot(i)"></span>
                      <span class="tickCrossSpan">
                        <span *ngFor="let showtick of AnswerSubmittedValues">
                          <i class="fa fa-check" style="color: #61CE70;"
                            *ngIf="showtick?.correct == '1' && showtick?.index == this.allQuestions[i]?.id && (quizMode != 'exam' || viewMode == true) "></i>
                          <i class="fa fa-times" style="color: #E34F6B;"
                            *ngIf="showtick?.correct == '0' && showtick?.index == this.allQuestions[i]?.id && (quizMode != 'exam' || viewMode == true) "></i>
                          <i class="fa fa-minus-circle" style="color: rgb(23, 201, 255);"
                            *ngIf="showtick?.correct == '-1' && showtick?.index == this.allQuestions[i]?.id && (quizMode != 'exam' || viewMode == true) "></i>
                        </span>
                      </span>
                      <span class="question-counter-value numberCounterSpan"
                        [ngClass]="((allQuestions[i].priority == 0)? 'firstQuestion': (allQuestions[i].priority == 1)? 'secondQuestion':'')">{{i+1}}</span>
                      <span class="markedQuestionSpan">
                        <span *ngFor="let flagged of markedflagsIndex">
                          <i class="fa fa-flag" *ngIf="flagged.QuestionId == this.allQuestions[i].id"></i>
                        </span>
                      </span>
                      <span class="markedQuestionSpan">
                        <span *ngFor="let noted of  markednotesIndex">
                          <i class="fa fa-pencil" *ngIf="noted.QuestionId == this.allQuestions[i].id"></i>
                        </span>
                      </span>
                    </div>
                  </div>



                </div>
              </div>

            </div>
          </div>

          <div class="csq-right-parent" [ngClass]="displaySidebar?'csq-flex-90':'csq-flex-100'"
            [ngClass]="{'csq-black-theme':isBlackTheme,'csq-light-theme': isLightTheme, 'csq-default-theme': !isLightTheme && !isBlackTheme}">

            <div class="csq-top-nav-bar-wrapper p-pt-1">
              <!--  Navbar bar area -->
              <div class="p-col-12 p-pt-0 p-pl-0 navbar" id="navbar" [ngClass]="displaySidebar?'':'nav100'">
                <div class="csq-relative-position">
                  <ul class="csq-navbar-actions-list">
                    <li class="display_on_Mobile">
                      <!-- (click)="showOnMobile.toggle($event)" -->
                      <button class="btn-nav" (click)="leftSidebar = true">
                        <span><i class="pi pi-align-justify csq-settings-btn-icon"></i></span>
                      </button>
                    </li>
                    <li class="display_on_Mobile">

                      <button class="btn-nav" (click)="showOnMobile.toggle($event)">
                        <span><i class="pi pi-sliders-v csq-settings-btn-icon"></i></span>
                      </button>
                    </li>
                    <li id="toggleBtn" class="sidebar-toggle-btn csq-sm-display-none" (click)="toggleclick()">
                      <span><i class="fa fa-bars"></i></span>
                    </li>
                    <li class="questions-counter display-block csq-sm-display-none">
                      <p>Item {{currentQuestionIndex+AdditionVariable}} of {{allQuestions.length}}</p>
                      <span class="hidefortab">Question Id: {{ allQuestions[currentQuestionIndex].UWorldID ? allQuestions[currentQuestionIndex].UWorldID : allQuestions[currentQuestionIndex].id }}</span>
                    </li>
                    <li class="mark-question csq-sm-display-none" style="display: flex;">
                      <span><input type="checkbox" (click)="markQuestion()" [checked]="flagmarked == true"></span>
                      <span class="p-ml-1"><i class="pi pi-flag" [ngClass]="flagmarked?'red':'white-color'"></i></span>
                      <span class="csq-mark-question-text csq-sm-display-none">Mark</span>
                    </li>
                    <li class="csq-navbar-nav-btns-wrapper">
                      <button class="btn-navigate-prev btn-nav" (click)="prevQuestion()">
                        <i class="pi pi-angle-left fa-2x csq-settings-btn-icon"></i>
                        <span class="csq-sm-display-none">Previous</span>
                      </button>
                      <button class="btn-navigate-next btn-nav" (click)="nextQuestion()">
                        <i class="pi pi-angle-right fa-2x csq-settings-btn-icon"></i>
                        <span class="csq-sm-display-none">Next</span>
                      </button>
                    </li>
                    <li class="csq-toggle-fs-wrapper csq-sm-display-none">
                      <button class=" btn-nav" (click)="toggleFullScreen()">
                        <span><i class="pi pi-window-maximize fa-2x csq-settings-btn-icon"></i></span>
                        <span class="btn-full-screen-span csq-sm-display-none">Full Screen</span>
                      </button>
                    </li>
                    <li (click)="toggleLabValues()" class="csq-sm-display-none">
                      <button class=" btn-nav ">
                        <span><i class="fa fa-thermometer-4 csq-settings-btn-icon"></i></span>
                        <span class="btn-full-screen-span csq-sm-display-none">Lab Values</span>
                      </button>
                    </li>
                    <li class="csq-sm-display-none csq-sm-display-none">
                      <button class=" btn-nav " (click)="displayModal=!displayModal;">
                        <span><i class="pi pi-file-o csq-settings-btn-icon"></i></span>
                        <span class="btn-full-screen-span ">Notes</span>
                      </button>
                    </li>
                    <li class="csq-sm-display-none csq-sm-display-none">
                      <button class=" btn-nav " (click)="shownotebookdialog=!shownotebookdialog; quizEdit = false">
                        <span><i class="pi pi-book csq-settings-btn-icon"></i></span>
                        <span class="btn-full-screen-span ">Notebook</span>
                      </button>
                    </li>
                    <li (click)="displayCalculatorModal = !displayCalculatorModal" class="csq-sm-display-none">
                      <button class=" btn-nav">
                        <span><i class="fa fa-calculator csq-settings-btn-icon"></i></span>
                        <span class="btn-full-screen-span csq-sm-display-none">Calculator</span>
                      </button>
                    </li>
                    <li class="csq-sm-display-none">
                      <button class=" btn-nav" (click)="reverseColor.toggle($event)">
                        <span><i class="pi pi-palette csq-settings-btn-icon"></i></span>
                        <span class="btn-full-screen-span">Reverse Color</span>
                      </button>
                    </li>
                    <li class="csq-sm-display-none">
                      <button class=" btn-nav" (click)="customizeFontSize.toggle($event)">
                        <span><i class="pi pi-search-plus csq-settings-btn-icon"></i></span>
                        <span class="btn-full-screen-span">Text Zoom</span>
                      </button>
                    </li>


                    <li class="">
                      <button class=" btn-nav" (click)="settings.toggle($event)">
                        <span><i class="fa fa-cogs csq-settings-btn-icon"></i></span>
                        <span class="btn-full-screen-span csq-sm-display-none">Settings</span>
                      </button>
                    </li>
                  </ul>

                </div>

              </div>
            </div>
            <!-- NABVAR ENDS HERE -->

            <div class="csq-modals-dialog-panels-wrapper">
              <!--  All modal boxes and dialogs -->
              <!--  All modal boxes and dialogs -->
              <p-dialog header="Edit Notes Item" [(visible)]="displayModal" position="bottom-right" [modal]="false"
                [style]="{bottom:'8%',right:'-8px','padding':'0'}" [baseZIndex]="10000" [draggable]="false"
                [resizable]="false">
                <form #notesForm="ngForm" class="csq-form">
                  <div class="p-dialog-content">
                    <textarea rows="12" cols="57" class="csq-feeback-input" required #notes="ngModel" name="notes"
                      [(ngModel)]="userNotes"></textarea>

                    <div class="csq-feedback-tech-issue-parent-wrapper">
                      <button class="modal-btn" (click)="saveNotes()">Save & Close</button>
                      <button class="modal-btn" (click)="saveNotes()" [disabled]="notes.invalid"
                        [ngClass]="notes.invalid?'cursor-not-allowed':'cursor-pointer'">Submit</button>
                    </div>
                  </div>
                </form>

              </p-dialog>

              <p-dialog header="Feedback" [(visible)]="displayFeedbackModal" position="bottom-right" [modal]="false"
                [style]="{height: '60vh',bottom:'8%',right:'-8px','padding':'0'}" [baseZIndex]="10000"
                [draggable]="false" [resizable]="false">
                <form #feedbackForm="ngForm" class="csq-form">
                  <div class="p-dialog-content">
                    <textarea rows=18 cols="57" class="csq-feeback-input" [(ngModel)]="userFeedback" required
                      #feedback="ngModel" name="feedback"
                      placeholder="We appreciate your feedback. However, please provide as much detail as possible in the permitted space avoiding simplistic feedback like Good question,hard question etc."></textarea>

                    <div class="csq-feedback-tech-issue-parent-wrapper">
                      <div class="csq-feedback-tech-issue-wrapper">
                        <input type="checkbox" [(ngModel)]="isTechIssue" name="techIssueCheckbox">
                        <span>Check here if concern is for software/technical issue.</span>
                      </div>
                      <button class="modal-btn w90" (click)="submitFeedback()" [disabled]="feedback.invalid"
                        [ngClass]="feedback.invalid?'cursor-not-allowed':'cursor-pointer'">Submit</button>
                    </div>
                  </div>
                </form>
              </p-dialog>


              <p-dialog header="Calculator" [(visible)]="displayCalculatorModal" position="bottom-right" [modal]="false"
                [style]="{right:'-8px',bottom:'8%','padding':'0'}" [baseZIndex]="10000" [draggable]="false"
                [resizable]="false">

                <app-calculator></app-calculator>

              </p-dialog>

              <p-overlayPanel #showOnMobile
                [style]="{'background-color':'#F1F1F1','width':'230px','max-height':'400px','padding':'10px' , 'overflow-y': 'scroll'}">
                <ng-template pTemplate>
                  <div>
                    <li class="questions-counter-mob ">
                      <p>Item {{currentQuestionIndex+AdditionVariable}} of {{allQuestions.length}}</p>
                      <span class="">Question Id: {{allQuestions[currentQuestionIndex].id}}</span>
                    </li>
                  </div>
                  <div>
                    <li class="mark-question1 mb-2" style="display: flex;">
                      <span><input type="checkbox" (click)="markQuestion()" [checked]="flagmarked == true"></span>
                      <span class="p-ml-1"><i class="pi pi-flag fa-2x"
                          [ngClass]="flagmarked?'red':'black-color'"></i></span>
                      <span class="csq-mark-question-text ">Mark</span>
                    </li>
                  </div>
                  <div>
                    <span class="csq-toggle-fs-wrapper1 mb-2">
                      <button class=" btn-mob" (click)="toggleFullScreen()" style="display: flex;">
                        <span><i class="pi pi-window-maximize fa-2x csq-settings-btn-icon mr-1"
                            style="font-size: 24px;"></i></span>
                        <span class="btn-full-screen-span ml-1" style="font-size: 16px;">Full Screen</span>
                      </button>
                    </span>
                  </div>
                  <div>
                    <span (click)="toggleLabValues()" class="mb-2">
                      <button class=" btn-mob " style="display: flex;">
                        <span><i class="fa fa-thermometer-4 fa-2x csq-settings-btn-icon mr-1"
                            style="font-size: 24px;"></i></span>
                        <span class="btn-full-screen-span mt-1 ml-1" style="font-size: 16px;">Lab Values</span>
                      </button>
                    </span>
                  </div>
                  <div>
                    <span class="">
                      <button class=" btn-mob mb-2" (click)="displayModal=!displayModal;" style="display: flex;">
                        <span><i class="pi pi-file-o csq-settings-btn-icon" style="font-size: 24px;"></i></span>
                        <span class="btn-full-screen-span " style="font-size: 16px;">Notes</span>
                      </button>
                    </span>
                  </div>
                  <div>
                    <span (click)="displayCalculatorModal = !displayCalculatorModal">
                      <button class=" btn-mob" style="display: flex;">
                        <span><i class="fa fa-calculator csq-settings-btn-icon" style="font-size: 24px;"></i></span>
                        <span class="btn-full-screen-span" style="font-size: 16px;">Calculator</span>
                      </button>
                    </span>
                  </div>
                  <div style="display: flex; align-items: center; margin-right: 10px;">
                    <label style="margin-right: 10px;">Tutor</label>
                    <p-inputSwitch [(ngModel)]="timeMode" (ngModelChange)="changeMode()"></p-inputSwitch>
                    <label style="margin-left: 10px;">Exam</label>
                  </div>
                  <div style="display: flex; align-items: center; margin-right: 10px;">
                    <label style="margin-right: 10px;">Timed</label>
                    <p-inputSwitch [(ngModel)]="timedMode" (ngModelChange)="changeTimed()"></p-inputSwitch>
                  </div>
                </ng-template>
              </p-overlayPanel>


              <p-overlayPanel #customizeFontSize id=""
                [style]="{'background-color':'#F1F1F1','width':'330px','max-height':'400px','padding':'10px'}">
                <ng-template pTemplate>
                  <div class="settings-dropdown-heading">
                    <span>Text Zoom</span>
                  </div>
                  <div class="settings-card">
                    <span class="card-heading">Change Text Size</span>
                    <div class="card settings-card">
                      <div class="settings-option no-top-border">
                        <div class="flex-1">
                          <span class="settings-option-label">Font Size</span>
                        </div>
                        <div class="settings-btn-group">
                          <span (click)="decreaseFontSize()"><i class="fa fa-minus"></i></span>
                          <span (click)="resetFontSize()"><i class="fa fa-font"></i></span>
                          <span (click)="increaseFontSize()"><i class="fa fa-plus"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>

                </ng-template>
              </p-overlayPanel>

              <p-overlayPanel #settings
                [style]="{'background-color':'#F1F1F1','width':'330px','max-height':'400px','padding':'10px' , 'overflow-y': 'scroll'}">
                <ng-template pTemplate>
                  <div class="settings-dropdown-heading">
                    <span>Settings</span>
                  </div>
                  <div class="settings-card">
                    <span class="card-heading">Appearance</span>
                    <div class="card settings-card">
                      <div class="settings-option no-top-border">
                        <div class="flex-1">
                          <span class="settings-option-label">Font Size</span>
                        </div>
                        <div class="settings-btn-group">
                          <span (click)="decreaseFontSize()"><i class="fa fa-minus"></i></span>
                          <span (click)="resetFontSize()"><i class="fa fa-font"></i></span>
                          <span (click)="increaseFontSize()"><i class="fa fa-plus"></i></span>
                        </div>
                      </div>
                      <div class="settings-option">
                        <div class="flex-1">
                          <span class="settings-option-label">Color Theme</span>
                        </div>
                        <div class="settings-btn-group">
                          <span class="theme-option theme-option-1" (click)="switchDefaultTheme()"></span>
                          <span class="theme-option theme-option-2" (click)="switchBlackTheme()"></span>
                          <span class="theme-option theme-option-3" (click)="switchLightTheme()"></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="settings-card night-mode-heading">
                    <span class="card-heading ">Night Mode</span>
                    <p class="fs-10">If on, auto changes to dark mode based on scheduled time</p>
                    <div class="card settings-card">
                      <div class="settings-option no-top-border">
                        <div class="flex-1">
                          <span class="settings-option-label">Automatic</span>
                        </div>
                        <div class="settings-btn-group">
                          <span>
                            <label class="switch">
                              <input type="checkbox" [checked]="automaticNightMode" (change)="TriggerAutoNightMode()">
                              <span class="slider round"></span>
                            </label>
                          </span>
                        </div>
                      </div>
                      <div class="settings-option">
                        <div class="flex-1">
                          <span class="settings-option-label "
                            [ngClass]="automaticNightMode?'black-color':'grey-color'">Start</span>
                        </div>
                        <div class="settings-btn-group">
                          <span class="night-mode-timer" [ngClass]="automaticNightMode?'black-color':'grey-color'">
                            <p-calendar [showIcon]="false" [(ngModel)]="startTime" [timeOnly]="true"
                              (ngModelChange)="turnAutomaticNightModeOn()" inputId="timeonly2" hourFormat="12"
                              appendTo="body"></p-calendar>
                          </span>
                        </div>
                      </div>
                      <div class="settings-option">
                        <div class="flex-1">
                          <span class="settings-option-label"
                            [ngClass]="automaticNightMode?'black-color':'grey-color'">End</span>
                        </div>
                        <div class="settings-btn-group">
                          <span class="night-mode-timer" [ngClass]="automaticNightMode?'black-color':'grey-color'">
                            <p-calendar [(ngModel)]="endTime" [timeOnly]="true" inputId="timeonly"
                              (ngModelChange)="turnAutomaticNightModeOn()" hourFormat="12" appendTo="body"></p-calendar>

                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="settings-card night-mode-heading">
                    <span class="card-heading">Features</span>
                    <div class="card settings-card">
                      <div class="settings-option no-top-border">
                        <div class="flex-1">
                          <span class="settings-option-label">Confirm Answer Omission</span>
                        </div>
                        <div class="settings-btn-group">
                          <span>
                            <label class="switch">
                              <input type="checkbox" (input)="confirmOmission()" [(ngModel)]="confirmAnsOmission">
                              <span class="slider round"></span>
                            </label>
                          </span>
                        </div>
                      </div>
                      <div class="settings-option">
                        <div class="flex-1">
                          <span class="settings-option-label">Show Timer</span>
                        </div>
                        <div class="settings-btn-group">
                          <span>
                            <label class="switch">
                              <input type="checkbox" [checked]="showTimer" (change)="showTimerfn()">
                              <span class="slider round"></span>
                            </label>
                          </span>
                        </div>
                      </div>
                      <div class="settings-option">
                        <div class="flex-1">
                          <span class="settings-option-label">Split Screen Explanations</span>
                        </div>
                        <div class="settings-btn-group">
                          <span>
                            <label class="switch">
                              <input type="checkbox" [checked]="splitScreenExplanations"
                                (change)="chngesplitScreenExplanations()">
                              <span class="slider round"></span>
                            </label>
                          </span>
                        </div>
                      </div>
                      <div class="settings-option">
                        <div class="flex-1">
                          <span class="settings-option-label">Highlight-Only Mode</span>
                        </div>
                        <div class="settings-btn-group">
                          <span>
                            <label class="switch">
                              <input type="checkbox" [checked]="highlightOnlyMode"
                                (change)="changeHighlightMode()">
                              <span class="slider round"></span>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                </ng-template>
              </p-overlayPanel>


              <p-overlayPanel #reverseColor
                [style]="{'background-color':'#F1F1F1','width':'330px','max-height':'400px','padding':'10px'}">
                <ng-template pTemplate>
                  <div class="settings-dropdown-heading">
                    <span>Reverse Colors</span>
                  </div>
                  <div class="settings-card p-mt-2">
                    <span class="card-heading">Select Layout Color</span>
                    <div class="card settings-card">

                      <div class="settings-option no-top-border">
                        <div class="flex-1">
                          <span class="settings-option-label">Color Theme</span>
                        </div>
                        <div class="settings-btn-group">
                          <span class="theme-option theme-option-1" (click)="switchDefaultTheme()"></span>
                          <span class="theme-option theme-option-2" (click)="switchBlackTheme()"></span>
                          <span class="theme-option theme-option-3" (click)="switchLightTheme()"></span>
                        </div>
                      </div>
                    </div>
                  </div>

                </ng-template>
              </p-overlayPanel>

              <!-- [ngClass]="timesUp?'display-block':'display-none'" -->
              <div id="timeUpModal" class="modal ">

                <!-- Modal content -->
                <!-- <div class="modal-content p-col-12 p-p-0">
                  <div class="modal-header">
                    <h3>Time's Up!</h3>
                  </div>
                  <div class="modal-text">
                    <p>Quiz has ended.</p>
                    <p>Go to Home screen to view results.</p>
                  </div>
                  <div class="modal-footer">
                    <button class="modal-footer-yes-btn" (click)="saveQuiz()">Go to Home Screen</button>
                  </div>
                </div> -->


                <!-- <p-confirmDialog header="Confirm Submission" icon="pi pi-exclamation-triangle"></p-confirmDialog> -->
              </div>

              <!-- Table Click Popup -->
              
              <span id="controlTable"><b id="tableNotebook"></b><b style="width: 65px;" id="tableNewFlashcard"></b><b id="tableExistingFlashcard"></b><i id="tableExit" class="fa fa-times"></i></span>
              
              <!-- ------------------- -->

              <div class=" p-p-0 csq-main-body-area" id="mainBodyArea">
                <div class="p-col-12  csq-main-area-content">
                  <div class="p-grid ">
                    <div class="p-col-12">
                      <!-- Main quiz area -->
                      <div class="csq-main-content">


                        <div>
                          <span id="control"><b id="highlightingIcon"></b><b id="addingtotext"></b><b id="notebook"></b><b style="width: 65px;" id="newFlashcard"></b><b id="existingFlashcard"></b>
                          </span>
                          <div>
                            <div class="question-answer-explanation"
                              [ngClass]="(splitScreenExplanations== true && AnswerSubmitted == true)?'eup-question-answer-explanation':''">
                              <div class="linkedQuestion" *ngIf="isLinkedQuestion && isFirstQuestion">The following
                                vignette applies to the next 2 items. The items in the set must be answered in
                                sequential order. <br> Once you click <b>Proceed to Next Item,</b> you will not be able
                                to add or change an answer.</div>
                              <div id="question_wrapper" class=""
                                [ngClass]="(splitScreenExplanations && AnswerSubmitted)?'eup_questions_Options_area':''">
                                <div class="csq-quiz-question-area" id="questionArea">
                                  <div class="question-data" style="padding-right: 22px;" id="question1"
                                    [innerHTML]="allQuestions[currentQuestionIndex].question | sanitizer"
                                    [ngClass]="isBlackTheme?'white-color':'black-color'"
                                    (click)="(mobView==false)?highlightTxt($event):null"
                                    (touchend)="(mobView==true || isIpad)?highlightTxt($event):null"
                                    (mouseup)="(isIpad)?highlightTxt($event):null">No Question in this quiz.</div>
                                </div>
                                <!-- <div *ngIf="isLinkedQuestion"><b>Item {{(isLinkedQuestion && isFirstQuestion? '1':'2')}} of 2</b></div> -->
                                <div class="csq-question-options-area">
                                  <div class="csq-question-options" id="optionsArea">
                                    <div class="csq-question-option-single-wrapper"
                                      *ngFor="let option of allQuestions[currentQuestionIndex]?.options; let i = index">
                                      <span class="csq-question-option-single">
                                        <i [class.fa-close]="(AnswerSubmitted  === true && SelectedOptionIndex == i) && (iscorrectAns == false && (quizMode != 'exam' || viewMode))"
                                          [class.fa-check]="(AnswerSubmitted  === true && (quizMode != 'exam' || viewMode)) && (iscorrectAns == true && SelectedOptionIndex == i || CorrectAnswerIndex == i)"
                                          [ngClass]="iscorrectAns || CorrectAnswerIndex == i?'green':'red'"
                                          class="fa"></i>
                                      </span>
                                      <label class="container flex-basis-16">
                                        <input type="radio" class="mark-answer" name="radio" id="radio{{i}}"
                                          value="{{i}}" (click)="getOptionValue(i)"
                                          [disabled]="(AnswerSubmitted && quizMode != 'exam')||viewMode"
                                          [checked]="SelectedOptionIndex == i">
                                        <span class="checkmark" id="checkmark{{i}}"></span>
                                      </label>
                                      <div class="option-single" (click)="strikeThroughAns(i)"
                                        id="{{getOptionValue1(i)}}">
                                        <span class="question-option-area"
                                          [ngClass]="isBlackTheme?'white-color':'black-color'">
                                          <span> </span>
                                          <span> </span>
                                          <span [ngClass]="isBlackTheme?'white-color':'black-color'">{{option}}</span>
                                          <span
                                            *ngIf="showQuestionDetails == true && quizMode != 'exam' || showQuestionDetails == true && viewMode==true">
                                            <span>
                                              <span class="p-pl-4">({{(allOptionsSelected &&
                                                allOptionsSelected[i])?((allOptionsSelected[i]/totaloptionSelected)*multiplier).toFixed(1):0}}%)</span>
                                            </span>
                                          </span>

                                        </span>
                                      </div>

                                    </div>



                                  </div>
                                  <div class="csq-submit-btn-wrapper">
                                    <button *ngIf="quizMode != 'exam'" class="submit-btn mb-5" (click)="onsubmitofQuestion()">
                                      <span>
                                        Submit
                                      </span>
                                    </button>
                                    <button *ngIf="quizMode == 'exam' && currentQuestionIndex+AdditionVariable != allQuestions.length" class="submit-btn mb-5" (click)="nextQuestion()">
                                      <span>
                                        Proceed to Next Item
                                      </span>
                                    </button>
                                  </div>

                                </div>
                                <div>
                                  <div class="answer-stats-area "
                                    [ngClass]="iscorrectAns?'greenborder':'' || (!iscorrectAns && !OmittedAnswer)?'redborder':'' || OmittedAnswer?'':'' "
                                    *ngIf="showQuestionDetails == true && quizMode != 'exam' || showQuestionDetails == true && viewMode==true">
                                    <div class="answer-stats ">
                                      <div class="answer-status p-grid">
                                        <div class="p-col-12">
                                          <p [ngClass]="iscorrectAns?'green':'red'" *ngIf="SelectedOptionIndex != -1">
                                            {{iscorrectAns?'Answered Correctly':'Incorrect Answer'}}
                                          </p>
                                          <p *ngIf="SelectedOptionIndex == -1">
                                            Omitted Answer
                                          </p>
                                          <span [ngClass]="isBlackTheme?'white-color':'black-color'">Correct
                                            answer</span>
                                          <br>
                                          <span [ngClass]="isBlackTheme?'white-color':'black-color'"
                                            style="font-size: 12px;" class="correct-answer-value"
                                            [innerHTML]="allQuestions[currentQuestionIndex].options[CorrectAnswerIndex]">This</span>
                                        </div>

                                      </div>
                                      <div class="answer-status-graph p-grid">
                                        <span class="answer-status-graph-graph">
                                          <i class="pi pi-clock fa-2x"></i>
                                        </span>
                                        <span class="answer-status-graph-value">
                                          <span class="graph-percentage ml-2"> <span *ngIf="hasMins"
                                              style="color: black; font-size: 14px;">{{minsTaken}}min:</span>
                                            {{secsTaken}}sec</span><br>
                                          <span class="ml-2"> Time Taken</span>
                                        </span>
                                      </div>
                                      <div class="answer-status-graph p-grid">
                                        <span class="answer-status-graph-graph">
                                          <i class="pi pi-check-square fa-2x"></i>
                                        </span>
                                        <span class="answer-status-graph-value">
                                          <span class="graph-percentage ml-2"> {{(allOptionsSelected &&
                                            allOptionsSelected[CorrectAnswerIndex])?((allOptionsSelected[CorrectAnswerIndex]/totaloptionSelected)*multiplier).toFixed(1):0}}%</span><br>
                                          <span class="ml-2"> Answered Correctly</span>

                                        </span>
                                      </div>


                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="explanation_wrapper" id="explanation_wrapper" style="padding-right: 22px;"
                                [ngClass]="(splitScreenExplanations== true && AnswerSubmitted == true)?'eup_explanation_area':''"
                                i>
                                <div class="answer-explanation-area"
                                  *ngIf="showQuestionDetails == true && quizMode != 'exam' || showQuestionDetails == true && viewMode==true">
                                  <div class="explanation-nav-tab">
                                    <ul>
                                      <li [ngClass]="isBlackTheme?'white-color':'black-color'">Explanation</li>
                                    </ul>
                                  </div>
                                  <div (click)="(mobView==false)?highlightTxt($event):null"
                                    (touchend)="(mobView==true)?highlightTxt($event):null"
                                    class="explanation-description" id="explanationArea"
                                    [ngClass]="isBlackTheme?'white-color':'black-color'"
                                    [innerHTML]="allQuestions[currentQuestionIndex].correct_msg | sanitizer">
                                  </div>
                                  <hr style="margin-top: 1.75rem;">
                                  <div class="explanation-course mt-4" id="explanation-course"
                                    [ngClass]="isBlackTheme?'white-color':'black-color'">
                                    <div *ngIf="(allQuestions[currentQuestionIndex].subject)">
                                      <div class="blur">Subject: </div>
                                      <div [innerHTML]="allQuestions[currentQuestionIndex].subject"></div>
                                    </div>
                                    <div *ngIf="(allQuestions[currentQuestionIndex].system)">
                                      <div class="blur">System: </div>
                                      <div [innerHTML]="allQuestions[currentQuestionIndex].system"></div>
                                    </div>
                                    <div *ngIf="(allQuestions[currentQuestionIndex].topic)">
                                      <div class="blur">Topic: </div>
                                      <div [innerHTML]="allQuestions[currentQuestionIndex].topic"></div>
                                    </div>
                                  </div>
                                  <!-- <div  class="explanation-description" id="explanation-description1"
                                [ngClass]="isBlackTheme?'white-color':'black-color'"
                                     [innerHTML]="allQuestions[currentQuestionIndex].incorrect_msg" >
                                </div> -->
                                  <!-- <pinch-zoom>
                                  <img src="http://elhusseinyusmleprep.com/wp-content/uploads/2020/02/2019-06-05-978.png" />
                              </pinch-zoom>                               -->

                                  <!-- <div class="p-grid csq-question-meta-wrapper">
                                  <div class="p-col-9">
                                    <div class="question-meta-data">
                                      <div class="question-subject" *ngIf="currentQuestion?.q_subject">
                                        <p [ngClass]="isBlackTheme?'white-color':'black-color'">{{currentQuestion.q_subject}}</p>
                                        <span>Subject</span>
                                      </div>
                                      <div class="question-system" *ngIf="currentQuestion?.q_system">
                                        <p [ngClass]="isBlackTheme?'white-color':'black-color'">{{currentQuestion.q_system}}</p>
                                        <span>System</span>
                                      </div>
                                      <div class="question-topic" *ngIf="currentQuestion?.q_topic">
                                        <p [ngClass]="isBlackTheme?'white-color':'black-color'">{{currentQuestion.q_topic}}</p>
                                        <span>Topic</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="p-col-12 copyrights">
                                    <span [ngClass]="isBlackTheme?'white-color':'black-color'">Copyright &copy;. All rights reserved.</span>
                                  </div>
                                </div> -->
                                </div>

                              </div>
                              <!-- <div> -->
                              <!-- <div class="answer-explanation-area" >
                          <div class="explanation-nav-tab"  >
                            <ul>
                              <li  >Explanation</li>
                            </ul>
                          </div>
                          <div class="explanation-description" id="explanation-description">
                               currentQuestion.q_exp
                          </div> -->

                              <!-- <div class="p-grid csq-question-meta-wrapper">
                            <div class="p-col-9">
                              <div class="question-meta-data">
                                <div class="question-subject" >
                                  <p >currentQuestion.q_subject</p>
                                  <span>Subject</span>
                                </div>
                                <div class="question-system" >
                                  <p >currentQuestion.q_system</p>
                                  <span>System</span>
                                </div>
                                <div class="question-topic" >
                                  <p >currentQuestion.q_topic</p>
                                  <span>Topic</span>
                                </div>
                              </div>
                            </div>
                            <div class="p-col-12 copyrights">
                              <span >Copyright &copy;. All rights reserved.</span>
                            </div>
                          </div>
                        </div> -->
                              <!-- </div> -->
                            </div>



                          </div>
                        </div>


                        <!--                                 LAB VALUES                     -->
                        <div
                          [ngClass]="openRightSideBar?'csq-main-content-right-parent':'csq-main-content-right-parent-0'">

                          <!--          Right side bar-->

                          <div id="labValues" class="csq-sidebar ">
                            <div class="p-grid">
                              <div class="p-col-12">
                                <div class="p-grid p-p-1">
                                  <div class="p-col-12 p-col-md-6">
                                    <input type="text" (input)="searchLabValues($event)" [(ngModel)]="labValueFilter">
                                  </div>
                                  <div class="p-col-12 p-col-md-6 ">
                                    <input type="checkbox" (click)="isSiRefVals=!isSiRefVals"> <label class="p-ml-2">SI
                                      Reference Intervals</label>
                                  </div>
                                </div>
                              </div>
                              <div class="p-col-12 csq-lab-values-wrapper">
                                <div class="csq-lab-values-parent">
                                  <p-tabMenu [model]="items" #menuItems (click)="activateMenu()"></p-tabMenu>
                                  <div *ngIf="activeItem?.label==='Blood'">
                                    <p-scrollPanel [style]="{paddingRight: '0', height: '55vh'}"
                                      styleClass="custombar1">
                                      <p-table styleClass="p-datatable-striped" [value]="bloodLabValues">
                                        <ng-template pTemplate="header">
                                          <tr>
                                            <th>Blood, Plasma, Serum</th>
                                            <th>Reference Range</th>
                                            <th *ngIf="isSiRefVals"> SI Reference Interval</th>
                                          </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-item>
                                          <tr>
                                            <td class="csq-lab-values-item-title">
                                              <span class="csq-labvalues-search">{{item.title}}</span><br>
                                              <span *ngIf="item.subtitle.length>0">
                                                <span *ngFor="let subitem of item.subtitle">
                                                  <span class="csq-labvalues-search">{{subitem.title}}</span><br>
                                                </span>
                                              </span>
                                            </td>
                                            <td class="csq-lab-values-item-title">
                                              <span class="csq-labvalues-search">{{item.value}}</span>
                                              <br>
                                              <span *ngIf="item.subtitle.length>0">
                                                <span *ngFor="let subitem of item.subtitle">
                                                  <span class="csq-labvalues-search">{{subitem.value}}</span><br>
                                                </span>
                                              </span>
                                            </td>
                                            <td *ngIf="isSiRefVals" class="csq-lab-values-item-title">
                                              <span class="csq-labvalues-search">{{item.siValue}}</span><br>
                                              <span *ngIf="item.subtitle.length>0">
                                                <span *ngFor="let subitem of item.subtitle">
                                                  <span class="csq-labvalues-search">{{subitem.siValue}}</span><br>
                                                </span>
                                              </span>
                                            </td>
                                          </tr>
                                        </ng-template>
                                      </p-table>
                                    </p-scrollPanel>
                                  </div>
                                  <div *ngIf="activeItem?.label==='Serum'">
                                    <p-scrollPanel [style]="{paddingRight: '0', height: '55vh'}">
                                      <p-table styleClass="p-datatable-striped" [value]="HematologicLabValues">
                                        <ng-template pTemplate="header">
                                          <tr>
                                            <th>Serum</th>
                                            <th>Reference Range</th>
                                            <th *ngIf="isSiRefVals"> SI Reference Interval</th>
                                          </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-item>
                                          <tr>
                                            <td class="csq-lab-values-item-title">
                                              <span class="csq-labvalues-search">{{item.title}}</span><br>
                                              <span *ngIf="item.subtitle.length>0">
                                                <span *ngFor="let subitem of item.subtitle">
                                                  <span class="csq-labvalues-search">{{subitem.title}}</span><br>
                                                </span>
                                              </span>
                                            </td>

                                            <td class="csq-lab-values-item-title">
                                              <span class="csq-labvalues-search">{{item.value}}</span><br>
                                              <span *ngIf="item.subtitle.length>0">
                                                <span *ngFor="let subitem of item.subtitle">
                                                  <span class="csq-labvalues-search">{{subitem.value}}</span><br>
                                                </span>
                                              </span>

                                            </td>
                                            <td class="csq-lab-values-item-title" *ngIf="isSiRefVals">
                                              <span class="csq-labvalues-search">{{item.siValue}}</span><br>
                                              <span *ngIf="item.subtitle.length>0">
                                                <span *ngFor="let subitem of item.subtitle">
                                                  <span class="csq-labvalues-search">{{subitem.siValue}}</span><br>
                                                </span>
                                              </span>

                                            </td>
                                          </tr>

                                        </ng-template>
                                      </p-table>
                                    </p-scrollPanel>
                                  </div>
                                  <div *ngIf="activeItem?.label==='Cerebrospinal'">
                                    <p-scrollPanel [style]="{paddingRight: '0', height: '55vh'}">
                                      <p-table styleClass="p-datatable-striped" [value]="CerebrospinalLabValues">
                                        <ng-template pTemplate="header">
                                          <tr>
                                            <th>Cerebrospinal Fluid</th>
                                            <th>Reference Range</th>
                                            <th *ngIf="isSiRefVals"> SI Reference Interval</th>
                                          </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-item>
                                          <tr>
                                            <td class="csq-lab-values-item-title">
                                              <span class="csq-labvalues-search">{{item.title}}</span><br>
                                            </td>
                                            <td class="csq-lab-values-item-title">
                                              <span class="csq-labvalues-search">{{item.value}}</span><br>
                                            </td>
                                            <td class="csq-lab-values-item-title" *ngIf="isSiRefVals">
                                              <span class="csq-labvalues-search">{{item.siValue}}</span><br>
                                            </td>
                                          </tr>

                                        </ng-template>
                                      </p-table>
                                    </p-scrollPanel>
                                  </div>
                                  <div *ngIf="activeItem?.label==='Urine and BMI'">
                                    <p-scrollPanel [style]="{paddingRight: '0', height: '55vh'}">
                                      <p-table styleClass="p-datatable-striped" [value]="sweatLabValues">
                                        <ng-template pTemplate="header">

                                          <tr>
                                            <th>Urine</th>
                                            <th>Reference Range</th>
                                            <th *ngIf="isSiRefVals"> SI Reference Interval</th>
                                          </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-item>

                                          <tr>
                                            <td class="csq-lab-values-item-title">
                                              <span class="csq-labvalues-search">{{item.title}}</span><br>
                                              <span *ngIf="item.subtitle.length>0">
                                                <span *ngFor="let subitem of item.subtitle">
                                                  <span class="csq-labvalues-search">{{subitem.title}}</span><br>
                                                </span>
                                              </span>
                                            </td>
                                            <td class="csq-lab-values-item-title ">
                                              <span class="csq-labvalues-search">{{item.value}}</span><br>
                                              <span *ngIf="item.subtitle.length>0">
                                                <span *ngFor="let subitem of item.subtitle">
                                                  <span class="csq-labvalues-search">{{subitem.value}}</span><br>
                                                </span>
                                              </span>
                                            </td>
                                            <td *ngIf="isSiRefVals" class="csq-lab-values-item-title ">
                                              <span class="csq-labvalues-search">{{item.siValue}}</span><br>
                                              <span *ngIf="item.subtitle.length>0">
                                                <span *ngFor="let subitem of item.subtitle">
                                                  <span class="csq-labvalues-search">{{subitem.siValue}}</span><br>
                                                </span>
                                              </span>
                                            </td>
                                          </tr>
                                        </ng-template>
                                      </p-table>
                                    </p-scrollPanel>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>





                      </div>
                    </div>
                  </div>
                </div>




              </div>


              <!-- FOOTER  -->
              <div class="csq-footer-wrapper">
                <!--  Footer -->
                <div class="footer p-grid p-m-0 fixed-bottom" id="footer"
                  [ngClass]="displaySidebar?'footer90':'footer100'">

                  <!-- showTimer &&  -->
                  <div class="timer" *ngIf="!viewMode && showTimer">
                    <div class="block-time-div ml-3">
                      <span *ngIf="showTimer"> <span class="csq-sm-display-none">Block </span> Time:
                        {{timerDisplay}}</span><br>
                      <!-- <span style="font-weight: 700;font-size: 12px">TUTOR</span> -->
                    </div>
                  </div>
                  <div style="width: 100px;" class="" *ngIf="viewMode || !showTimer">

                  </div>
                  <div class="logo_footer csq-sm-display-none">
                    <div class="">
                      <img class="ml-1" src="../../../assets/images/logo1.png" alt="" height="45px">
                    </div>
                  </div>


                  <div class="footer_btns  csq-footer-nav-bar pb-1 pt-1">
                    <ul class=" ">
                      <li style="display: flex; align-items: center; margin-right: 40px;" class="desktop" *ngIf="!viewMode">
                        <label style="margin-right: 10px; font-size: 13px;">Timed</label>
                        <p-inputSwitch [(ngModel)]="timedMode" (ngModelChange)="changeTimed()"></p-inputSwitch>
                      </li>
                      <li style="display: flex; align-items: center; margin-right: 10px;" class="desktop" *ngIf="!viewMode">
                        <label style="margin-right: 10px; font-size: 13px;">Tutor</label>
                        <p-inputSwitch [(ngModel)]="timeMode" (ngModelChange)="changeMode()"></p-inputSwitch>
                        <label style="margin-left: 10px; font-size: 13px;">Exam</label>
                      </li>
                      <li (click)="displayFlashcards = true; getUserFlashcards();" class="">
                        <button class=" btn-nav">
                          <span><i class="fa fa-bolt"></i></span>
                          <span class="btn-full-screen-span csq-sm-display-none">Flashcards</span>
                        </button>
                      </li>
                      <li (click)="displayFeedbackModal = true;" class="" *ngIf="!isDemo">
                        <button class=" btn-nav">
                          <span><i class="pi pi-comments"></i></span>
                          <span class="btn-full-screen-span csq-sm-display-none">Feedback</span>
                        </button>
                      </li>
                      <li *ngIf="!viewMode">
                        <button class=" btn-nav" (click)="displayEndBlockAlert(2)">
                          <span><i class="fa fa-pause"></i></span>
                          <span class="btn-full-screen-span">Suspend</span>
                        </button>
                      </li>
                      <li *ngIf="!viewMode">
                        <button class=" btn-nav" (click)="displayEndBlockAlert(1)">
                          <span><i class="fa fa-ban"></i></span>
                          <span class="btn-full-screen-span">End Block</span>
                        </button>
                      </li>

                      <li *ngIf="viewMode">
                        <button class=" btn-nav" (click)="GoToPerformanceScreen()">
                          <span><i class="fa fa-stop-circle-o"></i></span>
                          <span class="btn-full-screen-span">Go Back</span>
                        </button>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
              <!-- FOOTER ********** -->


            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- SIDEBAR For Mobile Starts -->
<p-sidebar [(visible)]="leftSidebar" [baseZIndex]="10000" [style]="{'overflow': 'scroll' , width:'160px'}">
  <div style="height: 19px;"></div>
  <div class="csq-question-side-navbar-wrapper">
    <div class="" [ngClass]="displaySidebar?'csq-flex-10':'csq-flex-0'">
      <div class="csq-sidenav pb-4" id="sidenav"
        [ngClass]="isBlackTheme?'csq-black-theme':isLightTheme?'csq-light-theme':'csq-default-theme'">
        <div class="sidebar-question-counter" *ngFor="let option of allQuestions; let i = index"
          (click)="getQuestion(i)">
          <div class="counter-div" [style.backgroundColor]="currentQuestionIndex===i?'#5590cc':i%2?'#e3e3e3':'#fff'">
            <span class="dot dotSpan" id="m{{i}}" *ngIf="showDot(i)">
              &#183;
            </span>
            <span class="dot dotSpan" *ngIf="!showDot(i)"></span>
            <span class="tickCrossSpan">
              <span *ngFor="let showtick of AnswerSubmittedValues">
                <i class="fa fa-check" style="color: #61CE70;"
                  *ngIf="showtick.correct == '1' && showtick.index == this.allQuestions[i].id && (quizMode != 'exam' || viewMode == true) "></i>
                <i class="fa fa-times" style="color: #E34F6B;"
                  *ngIf="showtick.correct == '0' && showtick.index == this.allQuestions[i].id && (quizMode != 'exam' || viewMode == true) "></i>
                <i class="fa fa-minus-circle" style="color: rgb(23, 201, 255);"
                  *ngIf="showtick.correct == '-1' && showtick.index == this.allQuestions[i].id && (quizMode != 'exam' || viewMode == true) "></i>
              </span>
            </span>
            <span class="question-counter-value numberCounterSpan">{{i+1}}</span>
            <span class="markedQuestionSpan">
              <span *ngFor="let flagged of markedflagsIndex">
                <i class="fa fa-flag" *ngIf="flagged.QuestionId == this.allQuestions[i].id"></i>
              </span>
            </span>
            <span class="markedQuestionSpan">
              <span *ngFor="let noted of  markednotesIndex">
                <i class="fa fa-pencil" *ngIf="noted.QuestionId == this.allQuestions[i].id"></i>
              </span>
            </span>
          </div>
        </div>



      </div>
    </div>

  </div>
</p-sidebar>
<!-- Sidebar For Mobile Ends -->

<!-- Notebook Feature Starts -->
<p-dialog header="Notebook" styleClass="notebookDialog" [(visible)]="shownotebookdialog" position="center" [modal]="true"
  [style]="{right:'-8px', 'padding':'0', width: '90vw', 'overflow':'hidden',height:'90vh','max-height': '90vh'}" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false">
  <div *ngIf="shownotebookdialog">
    <app-user-notebook [notebookText]=notebookText [quizEdit]="quizEdit" [html]="notebookText"></app-user-notebook>
  </div>
  <!-- <div class="notebookdialog">
    <p-editor [(ngModel)]="notebookText" [style]="{height:'40vh', width:'100%'}"></p-editor>
  </div> -->
</p-dialog>



<!-- Flashcard Feature -->
<p-dialog header="Flashcards" styleClass="flashcardDialog" [(visible)]="displayFlashcards" position="center" [modal]="true" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false" (onHide)="closeFlashcards()">
  <div class="elhusseniey-sas-flashcard-root" [ngClass]="(!isFullScreen)?'elhusseniey-sas-flashcard-root':'elhusseniey-sas-flashcard-root-expand'">
    <div class="elhusseniey-sas-flashcard-body">
        <!-- Header of the flashcard block -->
        <div class="elhusseniey-sas-flashcard-header flex-display align-center space-between">
            <div class="aku-elhusseniey-flashcard-heading-wrapper">
                <h2>Flashcards</h2>
            </div>
            <div class="aku-elhusseniey-flashcard-expand-close-btn-wrapper flex-display align-center ">
                <div class="aku-elhusseniey-flashcard-max-min-wrapper">
                    <button *ngIf="!isFullScreen" (click)="openFullscreen()" class="screenSize-btn flex-display align-center justify-center" style="padding-right: 10px;">
                        <i class="pi pi-window-maximize"></i>
                    </button>
                    <button *ngIf="isFullScreen" (click)="closeFullscreen()"  class="screenSize-btn flex-display align-center justify-center" style="padding-right: 10px;">
                        <i class="pi pi-window-minimize"></i>
                    </button>
                </div>
                <div class="flashcard" *ngIf="showFlashcard || createFlashcard">
                  <button class="screenSize-btn flex-display align-center justify-center" style="padding: 0px; padding-right: 5px;" (click)="showFlashcard = false; createFlashcard = false">
                    <i class="fa fa-th"></i>
                  </button>
                </div>
                <div class="aku-elhusseniey-flashcard-close-wrapper">
                    <button (click)="displayFlashcards = false"  class="closeModal-btn flex-display align-center justify-center">
                        <i class="pi pi-times"></i>
                    </button>
                </div>

            </div>

        </div>
        <div class="note">
          <strong>Keyboard Shortcuts:</strong> Copy -> CTRL + C and Paste -> CTRL + V
        </div>
        <!-- <div class="no_decks" *ngIf="decks.length <= 0">
          <span>Sorry, there are no decks available to add flashcards!</span>
        </div> -->
        <div class="search_flashcards" *ngIf="!showFlashcard && !createFlashcard">
          <input type="text" [(ngModel)]="flashcardKeyword" placeholder="Search flashcards"/>
          <i class="pi pi-search" style="cursor: pointer;" (click)="searchFlashcards()"></i>
        </div>
        <div class="flashcard_body" *ngIf="searcher">
          <div class="deck_content" style="justify-content: flex-start;">
              <i class="pi pi-arrow-left" style="color: rgba(0,0,0,.38); margin-right: 15px; cursor: pointer; font-size: 20px;" (click)="searcher = false; flashcardKeyword = ''"></i>
              <span>Search Results</span>
          </div>
          <div class="cards" *ngIf="searchedFlashcards.length > 0">
              <div class="single_card" *ngFor="let flashcard of searchedFlashcards; let i = index;" (click)="selectFlashcard(i, searchedFlashcards)">
                  <span [innerHTML]="flashcard.Question"></span>
              </div>
          </div>
          <div class="no_cards" *ngIf="searchedFlashcards.length <= 0">
              <span>No cards found.</span>
          </div>
        </div>
        <div class="flashcard_body" *ngIf="!showFlashcard && !createFlashcard && !searcher">
          <div class="question_flashcards">
            <span>This Question</span>
            <div class="cards">
              <div class="new_card" (click)="addNewCard()">
                <i class="pi pi-plus"></i>
                <span>New Card</span>
              </div>
              <div class="single_card" *ngFor="let flashcard of questionFlashcards; let i = index" (click)="selectFlashcard(i, questionFlashcards)">
                <span [innerHTML]="flashcard.Question"></span>
              </div>
            </div>
          </div>
          <div class="all_flashcards">
            <span>Other</span>
            <div class="cards">
              <div class="single_card" *ngFor="let flashcard of flashcards; let i = index" (click)="selectFlashcard(i, flashcards)">
                <span [innerHTML]="flashcard.Question"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="flashcard-decks" style="justify-content: space-between; width: 93%;" *ngIf="showFlashcard">
          <div class="deck">
              <div class="circle" [style.backgroundColor]="showFlashcards[selectedFlashcard]?.DeckColor">{{showFlashcards[selectedFlashcard]?.Deck[0]}}</div>
              <span>{{showFlashcards[selectedFlashcard]?.Deck}}</span>
          </div>
          <div class="view" (click)="changeView()">
              <span>{{flashcardView}}</span>
              <i style="margin-left: 10px;" class="pi pi-chevron-down"></i>
          </div>
          <div></div>
          <div class="options">
              <i class="pi pi-ellipsis-v" style="cursor: pointer;" (click)="displayCardOptions = true"></i>
              <div class="deck-dropdown" *ngIf="displayCardOptions">
                  <div class="close_dropdown">
                      <i class="pi pi-times" (click)="displayCardOptions = false"></i>
                  </div>
                  <div class="dropdown_content">
                      <div class="item" (click)="openEditFlashcardDialog(showFlashcards[selectedFlashcard])">
                          <i class="fa fa-pencil-square-o" style="margin-right: 10px;"></i>
                          <span>Edit</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- flashcard main body -->
      <div class="aku-elhusseniey-flashcard-main-wrapper flex-display align-center space-evenly" *ngIf="flashcardView == 'Single Side View' && showFlashcard">
          <div class="flashcard-front-wrapper" style="width: 100%;">
              <div class="flashcard-header flex-display align-center justify-end">
                  <span class="flex-display align-center justify-center" [ngStyle]="{'color': position == 'Back' ? '#2196F3' : 'black'}" (click)="position = 'Back'" style="cursor: pointer; width: 15%;">Back</span>
                  <span class="flex-display align-center justify-center" [ngStyle]="{'color': position == 'Front' ? '#2196F3' : 'black'}" (click)="position = 'Front'" style="cursor: pointer; width: 15%;">Front</span>
              </div>
              <div class="flashcard-editor-wrapper">
                  <div class="flashcard-text" *ngIf="position == 'Front'" [innerHTML]="showFlashcards[selectedFlashcard]?.Question"></div>
                  <div class="flashcard-text" *ngIf="position == 'Back'" [innerHTML]="showFlashcards[selectedFlashcard]?.Answer"></div>
                  <div class="tags" *ngIf="position == 'Back'">
                      <i class="pi pi-tag"></i>
                      <span *ngFor="let tag of showFlashcards[selectedFlashcard]?.Tags">{{tag}}</span>
                  </div>
              </div>
          </div>
      </div>
      <div class="aku-elhusseniey-flashcard-main-wrapper flex-display space-between" *ngIf="flashcardView == 'Double Side View' && showFlashcard">
          <div class="flashcard-front-wrapper" style="width: 48%; height: 350px;">
              <div class="flashcard-header flex-display align-center justify-end">
                  <span class="flex-display align-center justify-center">Front</span>
              </div>
              <div class="flashcard-editor-wrapper" style="height: 100%;">
                  <div class="flashcard-text" [innerHTML]="showFlashcards[selectedFlashcard]?.Question"></div>
              </div>
          </div> 
          <div class="flashcard-back-wrapper" style="width: 48%;" [ngStyle]="{'height': position == 'Back' ? '303px': '350px'}">
              <div class="flashcard-header flex-display align-center justify-end">
                  <span  class="flex-display align-center justify-center">Back</span>
              </div>
              <div class="flashcard-editor-wrapper" style="height: 100%;">
                  <div class="flashcard-text" style="background-color: #d9d9d9;" *ngIf="position == 'Front'"></div>
                  <div class="flashcard-text" [innerHTML]="showFlashcards[selectedFlashcard]?.Answer" *ngIf="position == 'Back'"></div>
                  <div class="tags" *ngIf="position == 'Back'">
                      <i class="pi pi-tag"></i>
                      <span *ngFor="let tag of showFlashcards[selectedFlashcard]?.Tags">{{tag}}</span>
                  </div>
              </div>
          </div>
      </div>
      <div class="flashcard-footer flex-display align-center space-between" style="flex-direction: column;" *ngIf="showFlashcard">
          <div class="flashcard-numbers">
              {{selectedFlashcard + 1}} of {{showFlashcards.length}}
          </div>
          <div class="flashcard-buttons">
              <button (click)="prevCardIndex()">
                  Previous
              </button>
              <button *ngIf="position == 'Front'" (click)="position = 'Back'">
                  Show Answer
              </button>
              <button *ngIf="position == 'Back'" (click)="position = 'Front'">
                  Hide Answer
              </button>
              <button (click)="nextCardIndex()">
                  Next
              </button>
          </div>
      </div>
      <div class="new_flashcard" *ngIf="createFlashcard">
        <div class="flashcard-decks" (click)="displayDropdown = !displayDropdown">
          <div class="circle" [style.backgroundColor]="selectedDeck?.Color">{{selectedDeck?.Title[0]}}</div>
          <span>{{selectedDeck?.Title}}</span>
          <i style="margin-left: 10px;" class="pi pi-chevron-down"></i>
        </div>
        <div class="deck-dropdown" *ngIf="displayDropdown">
            <div class="close_dropdown">
                <i class="pi pi-times" (click)="displayDropdown = false;"></i>
            </div>
            <div class="new_deck" (click)="displayDeck = true;" style="border-bottom: 1px solid #e7e7e7;">
              <i class="pi pi-plus"></i>
              <span>New Deck</span>
            </div>
            <div class="single_deck" *ngFor="let deck of decks; let i = index" [ngStyle]="{'border-bottom' : i < decks.length - 1 ? '1px solid #e7e7e7' : 'none'}" (click)="selectedDeck = deck; displayDropdown = false;">
                <div class="circle" [style.backgroundColor]="deck.Color"></div>
                <span>{{deck.Title}}</span>
            </div>
        </div>
        <!-- flashcard main body -->
        <div class="aku-elhusseniey-flashcard-main-wrapper flex-display align-center space-evenly">
            <div class="flashcard-front-wrapper">
                <div class="flashcard-header flex-display align-center justify-end">
                    <span class="flex-display align-center justify-center">Front</span>
                </div>
                <div class="flashcard-editor-wrapper">
                  <ckeditor id="ckEditorFront" [(ngModel)]="frontEditor"></ckeditor>
                </div>
            </div>
            <div class="flashcard-interchange-button-wrapper flex-display align-center justify-center">
                <button class="interchange-btn">
                    <i class="fa fa-exchange"></i>
                </button>
            </div>
            <div class="flashcard-back-wrapper">
                <div class="flashcard-header flex-display align-center justify-end">
                    <span  class="flex-display align-center justify-center">Back</span>
                </div>
                <div class="flashcard-editor-wrapper">
                  <ckeditor id="ckEditorBack" [(ngModel)]="backEditor"></ckeditor>
                </div>
            </div>
        </div>
        <div class="flashcard-chip-wrapper flex-display align-center ">
            <p-chips [(ngModel)]="card.tags" separator="," placeholder="Tag name, comma seperated">
            </p-chips>
        </div>
        <div class="flashcard-footer flex-display align-center space-between" style="padding: 0 !important;">
            <button class="clear-btn" (click)="createFlashcard = false;">
                Cancel
            </button>
            <button class="apply-btn" (click)="(existingFlashcard ? editDeckFlashcard() : addDeckFlashcard())">
                Save
            </button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog header="Edit Flashcard" styleClass="flashcardDialog" [(visible)]="displayEditFlashcard" position="center" [modal]="false" [baseZIndex]="10000" [draggable]="false"
[resizable]="false">
    <div class="elhusseniey-sas-flashcard-root" [ngClass]="(!isFullScreen)?'elhusseniey-sas-flashcard-root':'elhusseniey-sas-flashcard-root-expand'">
        <div class="elhusseniey-sas-flashcard-body">
            <!-- Header of the flashcard block -->
            <div class="elhusseniey-sas-flashcard-header flex-display align-center space-between">
                <div class="aku-elhusseniey-flashcard-heading-wrapper">
                    <h2>Flashcards</h2>
                </div>
                <div class="aku-elhusseniey-flashcard-expand-close-btn-wrapper flex-display align-center ">
                    <div class="aku-elhusseniey-flashcard-max-min-wrapper">
                        <button *ngIf="!isFullScreen" (click)="openFullscreen()" class="screenSize-btn flex-display align-center justify-center">
                            <i class="pi pi-window-maximize"></i>
                        </button>
                        <button *ngIf="isFullScreen" (click)="closeFullscreen()"  class="screenSize-btn flex-display align-center justify-center">
                            <i class="pi pi-window-minimize"></i>
                        </button>
                    </div>
                    <div class="aku-elhusseniey-flashcard-close-wrapper">
                        <button (click)="displayEditFlashcard = false"  class="closeModal-btn flex-display align-center justify-center">
                            <i class="pi pi-times"></i>
                        </button>
                    </div>
    
                </div>
    
            </div>
            <!-- flashcard main body -->
            <div class="aku-elhusseniey-flashcard-main-wrapper flex-display align-center space-evenly">
                <div class="flashcard-front-wrapper">
                    <div class="flashcard-header flex-display align-center justify-end">
                        <span class="flex-display align-center justify-center">Front</span>
                    </div>
                    <div class="flashcard-editor-wrapper">
                      <ckeditor id="ckEditorFront" [(ngModel)]="frontEditor"></ckeditor>
                    </div>
                </div>
                <div class="flashcard-interchange-button-wrapper flex-display align-center justify-center">
                    <button class="interchange-btn">
                        <i class="fa fa-exchange"></i>
                    </button>
                </div>
                <div class="flashcard-back-wrapper">
                    <div class="flashcard-header flex-display align-center justify-end">
                        <span  class="flex-display align-center justify-center">Back</span>
                    </div>
                    <div class="flashcard-editor-wrapper">
                      <ckeditor id="ckEditorBack" [(ngModel)]="backEditor"></ckeditor>
                    </div>
                </div>
            </div>
            <div class="flashcard-chip-wrapper flex-display align-center ">
                <p-chips [(ngModel)]="card.tags" separator="," placeholder="Tag name, comma seperated">
                </p-chips>
            </div>
            <div class="flashcard-footer flex-display align-center space-between" style="padding: 0 !important;">
                <button class="clear-btn" (click)="displayEditFlashcard = false">
                    Cancel
                </button>
                <button class="apply-btn" (click)="editUserFlashcard()">
                    Update
                </button>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="" styleClass="continueDialog" [(visible)]="displayContinue" position="center" [modal]="false" [baseZIndex]="10000" [draggable]="false">
  <span>Please select a card to continue</span>
</p-dialog>

<p-dialog header="" styleClass="cardDialog" [(visible)]="displayCards" position="center" [modal]="false" [baseZIndex]="10000" [draggable]="false" [modal]="true">
  <div class="addToFlashcard">
    <div class="heading">
      <span>Add To Flashcard</span>
    </div>
    <div class="content">
      <span>Please select which side you wish to add your selected content</span>
    </div>
    <div class="buttons">
      <button (click)="updateHTML('Front')">Front</button>
      <button (click)="updateHTML('Back')">Back</button>
    </div>
  </div>
</p-dialog>

<p-dialog header="Exhibit Display" styleClass="imgDialog" [(visible)]="displayImg" position="center" [modal]="false" [baseZIndex]="10000" [draggable]="false" [modal]="true">
  <div class="imgDialog_root">
    <div class="showImage" id="pic">
      <pinch-zoom [limit-zoom]="4">
        <img src={{showImage}}>
      </pinch-zoom>
    </div>
    <div class="showOptions">
      <span (click)="imgToNewFlashcard()"><i class="fa fa-bolt" style="margin-right: 10px;"></i>New Flashcard</span>
      <span (click)="imgToExistingFlashcard()"><i class="fa fa-bolt" style="margin-right: 10px;"></i>Existing Flashcard</span>
      <span (click)="imgToNotebook()"><i class="fa fa-book" style="margin-right: 10px;"></i>Add To Notebook</span>
    </div>
  </div>
</p-dialog>

<p-dialog header="Create Deck" styleClass="deckDialog" [(visible)]="displayDeck" [modal]="true" [style]="{width: '280px'}"
    [draggable]="false" [resizable]="false" [baseZIndex]="100000">
    <div class="createDeck">
        <input type="text" placeholder="Enter Name" [(ngModel)]="deck.title" [style.borderBottomColor]="deck.color">
        <div class="colors">
            <div [style.backgroundColor]="color" class="circle" *ngFor="let color of deckColors" (click)="deck.color = color">
                <i *ngIf="deck.color == color" class="pi pi-check"></i>
            </div>
        </div>
    </div>
    <div class="createButtons">
        <button (click)="displayDeck = false;">Cancel</button>
        <button (click)="addNewUserDeck()">Create</button>
    </div>

</p-dialog>