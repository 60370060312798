<app-overlay-loader *ngIf="loader"></app-overlay-loader>
<div class="eup_flashcard_study_root">
    <div class="eup_flashcard_study_parent">
        <div class="eup_study_decks">
            <div class="study_content" *ngIf="!selectedDeck && !viewStats">
                <div class="search_wrapper">
                    <input type="text" [(ngModel)]="keyword" placeholder="Search decks"/>
                    <i class="pi pi-search" style="cursor: pointer;" (click)="searchDecks()"></i>
                </div>
                <div class="create_buttons">
                    <div class="deck" (click)="getUpdatedStats()">
                        <i class="fa fa-bar-chart"></i>
                        <span>View Stats</span>
                    </div>
                </div>
            </div>
            <div class="deck_table" *ngIf="!selectedDeck && !viewStats">
                <p-table id="deck_table" #dt [value]="decks" [scrollable]="true" [totalRecords]="decks.length">
                    <ng-template pTemplate="header" class="table_header" styleClass="myTable" pResizableColumn>
                        <tr>
                            <th [ngStyle]="{'width':'250px'}">Choose Deck</th>
                            <th [ngStyle]="{'width':'120px', 'text-align': 'center'}">New Cards</th>
                            <th [ngStyle]="{'width':'120px', 'text-align': 'center'}">Learning</th>
                            <th [ngStyle]="{'width':'120px', 'text-align': 'center'}">To Review</th>
                            <th [ngStyle]="{'width':'120px', 'text-align': 'center'}">Last Used</th>
                            <th class="pr-5 mr-5" [ngStyle]="{'width':'120px'}">Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-deck let-i="rowIndex" class="table_body">
                        <tr>
                            <td [ngStyle]="{'width':'250px'}">
                                <div class="deck_content">
                                    <div class="circle" (click)="selectDeck(deck, i)" [style.backgroundColor]="deck.Color">{{deck?.Title[0]}}</div>
                                    <span (click)="selectDeck(deck, i)">{{deck.Title}}</span>
                                </div>
                            </td>
                            <td class="column" [ngStyle]="{'width':'120px', 'text-align': 'center', 'color': '#2196f3'}">{{deck.NewCards}}</td>
                            <td class="column" [ngStyle]="{'width':'120px', 'text-align': 'center', 'color': '#a94442'}">{{deck.Learning}}</td>
                            <td class="column" [ngStyle]="{'width':'120px', 'text-align': 'center', 'color': '#02a604'}">{{deck.ToReview}}</td>
                            <td class="column" [ngStyle]="{'width':'120px', 'text-align': 'center'}">{{(deck.LastUsed ? (deck.LastUsed | date: 'MMM dd, yyyy') : '-')}}</td>
                            <td class="actions pr-5" [ngStyle]="{'width':'120px'}"> 
                                <div class="action_wrapper">
                                    <i class="fa fa-cog" pTooltip="Settings" (click)="selectSetting(deck); displaySettings = true" *ngIf="!isDemo"></i>
                                    <i class="fa fa-arrow-circle-o-right" pTooltip="Study Now" (click)="(deck.Flashcards.length > 0 ? openFlashcard(i, 0) : selectDeck(deck, i))"></i>
                                </div> 
                            </td>  
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="single_deck" *ngIf="viewStats">
                <div class="header" (click)="viewStats = false">
                    <i class="pi pi-arrow-left"></i>
                    <span>Back to decks</span>
                </div>
                <div class="content">
                    <div class="deck_header">
                        <span>Statistics</span>
                    </div>
                    <div class="deck_stats">
                        <div class="single_stat">
                            <span><strong>Today</strong></span>
                            <div class="stat">
                                <span>You have studied {{totalStudyCards}} cards in {{totalStudyMins}} mins today ({{avgStudyMins}} secs/card)</span>
                            </div>
                        </div>
                        <hr>
                        <div class="single_stat">
                            <span><strong>Forecast</strong></span>
                            <div class="stat">
                                <span>Due tomorrow: {{totalDueCards}} cards</span>
                            </div>
                        </div>
                        <hr>
                        <div class="single_stat">
                            <span><strong>Review Count</strong></span>
                            <div class="stat">
                                <span>Total: {{totalCardReviews}} reviews</span>
                                <span>Average for days studied: {{avgReviews}} reviews/day</span>
                            </div>
                        </div>
                        <hr>
                        <div class="single_stat">
                            <span><strong>Time Spent</strong></span>
                            <div class="stat">
                                <span [innerHTML]="(totalTimeSpent ? 'Total: ' + totalTimeSpent : 'Total: 0 sec')"></span>
                                <span>Average for days studied: {{avgTime}} mins/day</span>
                                <span>Average answer time: {{avgAnswer}} secs ({{avgMins}} card/min)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="single_deck" *ngIf="selectedDeck">
                <div class="header" (click)="selectedDeck = null">
                    <i class="pi pi-arrow-left"></i>
                    <span>Back to decks</span>
                </div>
                <div class="content">
                    <div class="deck_header">
                        <div class="circle" [style.backgroundColor]="selectedDeck?.Color">{{selectedDeck?.Title[0]}}</div>
                        <span>{{selectedDeck?.Title}}</span>
                    </div>
                    <div class="deck_performance" *ngIf="selectedDeck.Flashcards.length > 0">
                        <div class="single_item">
                            <span>New Cards</span>
                            <span style="color: #2196f3">{{selectedDeck.NewCards}}</span>
                        </div>
                        <div class="single_item">
                            <span>Learning</span>
                            <span style="color: #a94442">{{selectedDeck.Learning}}</span>
                        </div>
                        <div class="single_item">
                            <span>To Review</span>
                            <span style="color: #02a604">{{selectedDeck.ToReview}}</span>
                        </div>
                    </div>
                    <div class="deck_performance" style="width: 70%; text-align: center;" *ngIf="selectedDeck.Flashcards.length <= 0">
                        <span style="font-size: 18px;">Good Job! You have finished the study session for this deck.
                            If you want to continue studying this deck, click on Reset button.</span>
                    </div>
                    <div class="deck_settings">
                        <button (click)="selectSetting(deck); displaySettings = true" *ngIf="!isDemo">Settings</button>
                        <button (click)="resetDeckPerformance()" *ngIf="!isDemo">Reset</button>
                        <button *ngIf="selectedDeck.Flashcards.length > 0" (click)="openFlashcard(selectedDeck.Index, 0)">Study Now</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Flashcards" styleClass="flashcardDialog" [(visible)]="displayFlashcards" [modal]="true" (onHide)="closeDialog()"
    [draggable]="false" [resizable]="false">
    <div class="elhusseniey-sas-flashcard-root" [ngClass]="(!isFullScreen)?'elhusseniey-sas-flashcard-root':'elhusseniey-sas-flashcard-root-expand'">
        <div class="elhusseniey-sas-flashcard-body">
            <!-- Header of the flashcard block -->
            <div class="elhusseniey-sas-flashcard-header flex-display align-center space-between">
                <div class="aku-elhusseniey-flashcard-heading-wrapper">
                    <h2>Flashcards</h2>
                </div>
                <div class="aku-elhusseniey-flashcard-expand-close-btn-wrapper flex-display align-center ">
                    <div class="aku-elhusseniey-flashcard-max-min-wrapper">
                        <button *ngIf="!isFullScreen" (click)="openFullscreen()" class="screenSize-btn flex-display align-center justify-center">
                            <i class="pi pi-window-maximize"></i>
                        </button>
                        <button *ngIf="isFullScreen" (click)="closeFullscreen()"  class="screenSize-btn flex-display align-center justify-center">
                            <i class="pi pi-window-minimize"></i>
                        </button>
                    </div>
                    <div class="aku-elhusseniey-flashcard-close-wrapper">
                        <button (click)="displayFlashcards = false"  class="closeModal-btn flex-display align-center justify-center">
                            <i class="pi pi-times"></i>
                        </button>
                    </div>
    
                </div>
    
            </div>
            <div class="flashcard-decks space-between" style="width: 93%;">
                <div class="deck flex-display align-center">
                    <div class="circle" [style.backgroundColor]="decks[deckActiveIndex]?.Color">{{decks[deckActiveIndex]?.Title[0]}}</div>
                    <span>{{decks[deckActiveIndex]?.Title}}</span>
                </div>
                <div class="view flex-display align-center" (click)="changeView()">
                    <span>{{flashcardView}}</span>
                    <i style="margin-left: 10px;" class="pi pi-chevron-down"></i>
                </div>
                <div class="options">
                    <i class="pi pi-ellipsis-v" style="cursor: pointer;" (click)="displayCardOptions = true" *ngIf="!isDemo"></i>
                    <div class="deck-dropdown" *ngIf="displayCardOptions">
                        <div class="close_dropdown">
                            <i class="pi pi-times" (click)="displayCardOptions = false"></i>
                        </div>
                        <div class="dropdown_content">
                            <div class="item" (click)="buryFlashcard(decks[deckActiveIndex]?.DeckID, decks[deckActiveIndex]?.Flashcards[cardActiveIndex].FlashcardID)">
                                <i class="fa fa-minus-circle" style="margin-right: 10px;"></i>
                                <span>Bury Card</span>
                            </div>
                            <div class="item" (click)="suspendFlashcard(decks[deckActiveIndex]?.DeckID, decks[deckActiveIndex]?.Flashcards[cardActiveIndex].FlashcardID)">
                                <i class="fa fa-eye-slash" style="margin-right: 10px;"></i>
                                <span>Suspend Card</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- flashcard main body -->
            <div class="aku-elhusseniey-flashcard-main-wrapper flex-display align-center space-evenly" *ngIf="flashcardView == 'Single Side View'">
                <div class="flashcard-front-wrapper" style="width: 100%;">
                    <div class="flashcard-header flex-display align-center justify-end">
                        <span class="flex-display align-center justify-center" [ngStyle]="{'color': position == 'Back' ? '#2196F3' : 'black', 'pointer-events': solved ? 'visible': 'none'}" (click)="updatePosition('Back')" style="cursor: pointer; width: 15%;">Back</span>
                        <span class="flex-display align-center justify-center" [ngStyle]="{'color': position == 'Front' ? '#2196F3' : 'black'}" (click)="updatePosition('Front')" style="cursor: pointer; width: 15%;">Front</span>
                    </div>
                    <div class="flashcard-editor-wrapper">
                        <div class="flashcard-text" *ngIf="position == 'Front'" [innerHTML]="decks[deckActiveIndex]?.Flashcards[cardActiveIndex]?.Question"></div>
                        <div class="flashcard-text" *ngIf="position == 'Back'" [innerHTML]="decks[deckActiveIndex]?.Flashcards[cardActiveIndex]?.Answer"></div>
                        <div class="tags" *ngIf="position == 'Back'">
                            <i class="pi pi-tag"></i>
                            <span *ngFor="let tag of decks[deckActiveIndex]?.Flashcards[cardActiveIndex]?.Tags">{{tag}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="aku-elhusseniey-flashcard-main-wrapper flex-display space-between" *ngIf="flashcardView == 'Double Side View'">
                <div class="flashcard-front-wrapper" style="width: 48%; height: 350px;">
                    <div class="flashcard-header flex-display align-center justify-end">
                        <span class="flex-display align-center justify-center">Front</span>
                    </div>
                    <div class="flashcard-editor-wrapper" style="height: 100%;">
                        <div class="flashcard-text" [innerHTML]="decks[deckActiveIndex]?.Flashcards[cardActiveIndex]?.Question"></div>
                    </div>
                </div> 
                <div class="flashcard-back-wrapper" style="width: 48%;" [ngStyle]="{'height': position == 'Back' ? '303px': '350px'}">
                    <div class="flashcard-header flex-display align-center justify-end">
                        <span  class="flex-display align-center justify-center">Back</span>
                    </div>
                    <div class="flashcard-editor-wrapper" style="height: 100%; background-color: #d9d9d9;">
                        <div class="flashcard-text" *ngIf="position == 'Front'" style="background-color: #d9d9d9; height: 302px;"></div>
                        <div class="flashcard-text" style="height: 302px;" [innerHTML]="decks[deckActiveIndex]?.Flashcards[cardActiveIndex]?.Answer" *ngIf="position == 'Back'"></div>
                        <div class="tags" *ngIf="position == 'Back'">
                            <i class="pi pi-tag"></i>
                            <span *ngFor="let tag of decks[deckActiveIndex]?.Flashcards[cardActiveIndex]?.Tags">{{tag}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flashcard-footer flex-display space-between" style="padding-right: 37px;">
                <div></div>
                <div class="footer_options flex-display align-center" style="flex-direction: column; justify-content: center;" *ngIf="!solved">
                    <div class="flashcard-numbers">
                        <span style="color: #2196f3;">{{decks[deckActiveIndex]?.NewCards}}</span> + <span style="color: #a94442;">{{decks[deckActiveIndex]?.Learning}}</span> + <span style="color: #02a604;">{{decks[deckActiveIndex]?.ToReview}}</span>
                    </div>
                    <div class="flashcard-buttons">
                        <button *ngIf="position == 'Front' && !solved" (click)="showAnswer()">
                            Show Answer
                        </button>
                    </div>
                </div>
                <div class="flashcard-buttons flex-display" *ngIf="solved">
                    <div class="button flex-column align-center justify-center" style="margin-left: 10px; margin-right: 10px;" *ngFor="let value of values">
                        <span style="margin-bottom: 15px;">{{value.value}}</span>
                        <button (click)="updateDeckPerformance(value)">{{value.text}}</button>
                    </div>
                </div>
                <div class="timer"><span *ngIf="decks[deckActiveIndex]?.Settings.showTimer">{{displayTimer}}</span></div>
            </div>
            <!-- <div *ngIf="solved" class="flashcard-footer flex-display align-center space-between" style="flex-direction: column;">
                <div class="flashcard-buttons flex-display">
                    <div class="button flex-column align-center justify-center" style="margin-left: 10px; margin-right: 10px;" *ngFor="let value of values">
                        <span style="margin-bottom: 15px;">{{value.value}}</span>
                        <button (click)="updateDeckPerformance(value)">{{value.text}}</button>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</p-dialog>

<p-dialog header="Settings" [(visible)]="displaySettings" [modal]="true" [style]="{width: '450px'}"
    [draggable]="false" [resizable]="false">
    <div class="settings">
        <div class="settings_options">
            <span *ngFor="let option of options; let i = index;" (click)="changeIndex(i)" [ngStyle]="{'color': i == activeIndex ? '#2196F3' : 'black', 'border-bottom': i == activeIndex ? '2px solid #2196F3' : 'none'}">{{option}}</span>
        </div>
        <hr>
        <div class="options">
            <div class="item" *ngIf="activeIndex == 0">
                <div class="option">
                    <label>Maximum cards a day</label>
                    <input type="number" [(ngModel)]="maxNewCards" min="1">
                </div>
                <div class="option" style="margin-top: 15px;">
                    <label>Order</label>
                    <p-dropdown [(ngModel)]="order" [options]="allOrders" appendTo="body"></p-dropdown>
                </div>
            </div>
            <div class="item" *ngIf="activeIndex == 1">
                <div class="option">
                    <label>Maximum cards a day</label>
                    <input type="number" [(ngModel)]="maxReviewCards" min="1">
                </div>
            </div>
            <div class="item" *ngIf="activeIndex == 2">
                <div class="option">
                    <label>Show answer timer</label>
                    <p-inputSwitch [(ngModel)]="showTimer"></p-inputSwitch>
                </div>
            </div>
        </div>
        <div class="error_message">
            {{errorMessage}}
        </div>
        <div class="buttons">
            <button (click)="displaySettings = false;">Cancel</button>
            <button (click)="applySettings()">Apply</button>
        </div>
    </div>
</p-dialog>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>

<p-dialog header="Exhibit Display" styleClass="imgDialog" [(visible)]="displayImg" position="center" [modal]="false" [baseZIndex]="10000" [draggable]="false" [modal]="true">
    <div class="imgDialog_root">
      <div class="showImage" id="pic">
        <pinch-zoom [limit-zoom]="4">
          <img src={{showImage}}>
        </pinch-zoom>
      </div>
    </div>
</p-dialog>

<p-toast></p-toast>