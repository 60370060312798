import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BASE_URL, createAuthorizationHeader} from "../../models/globals";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = BASE_URL;

  getUserId(){
    return localStorage.getItem('Id');
  }
  constructor(private httpClient: HttpClient) { }

  login(user: any){
    return this.httpClient.post(this.baseUrl + '/authenticateUser', {user});
  }
  authentication(user:any , code:any){
    return this.httpClient.post(this.baseUrl + '/verifyLogin', {user , code});
  }
  updatePass(user: any){
    return this.httpClient.post(this.baseUrl + '/updatePassword', {user},
    {headers: createAuthorizationHeader()});
  }
  getuserdetails(){
    return this.httpClient.get(this.baseUrl + `/getUser?userId=${this.getUserId()}`,
      {headers: createAuthorizationHeader()});
  }
  getUserSubscription(){
    return this.httpClient.post(this.baseUrl + '/getUserSubscription', {userId: this.getUserId()});
  }
  generateDemoToken(){
    return this.httpClient.get(this.baseUrl + `/generateDemoToken`);
  }

  generateTransaction(data: any){
    return this.httpClient.post(this.baseUrl + `/generateTransaction`, { data }, {headers: createAuthorizationHeader()});
  }

  verifyTransactionCode(data: any){
    return this.httpClient.post(this.baseUrl + `/verifyTransactionCode`, { data }, {headers: createAuthorizationHeader()});
  }

  // Opay APIs //

  opayTransaction(data: any) {
    return this.httpClient.post(this.baseUrl + `/generateOpayTransaction`, { data }, {headers: createAuthorizationHeader()});
  }

  getOpayStatus(data: any) {
    return this.httpClient.post(this.baseUrl + `/getOpayTransactionStatus`, { data }, {headers: createAuthorizationHeader()});
  }

  // Paymob APIs //

  paymobTokenGeneration() {
    return this.httpClient.post('https://accept.paymobsolutions.com/api/auth/tokens', { api_key: 'ZXlKaGJHY2lPaUpJVXpVeE1pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnVZVzFsSWpvaU1UVTRPRFV4TXpZd09TNDFOemt4TnpFaUxDSndjbTltYVd4bFgzQnJJam8xTWpFd0xDSmpiR0Z6Y3lJNklrMWxjbU5vWVc1MEluMC55Yzc1YVpzTVZtNnVSOWE0OXpMd0FlNHZsMjF4WGZjcEc1WTMyc3F5VE9reWRNV2tWaU9LYVQzS1MzTHdrV1gwdHNxaUhCVmVzSk1SVzlyQnBocXBIdw==' });
  }

  paymobOrderCreation(data: any) {
    return this.httpClient.post('https://accept.paymobsolutions.com/api/ecommerce/orders', { 
      auth_token: data.auth_token, 
      delivery_needed: data.delivery_needed,  
      amount_cents: data.amount_cents,
      currency: data.currency,
      items: data.items,
      shipping_data: data.shipping_data
    });
  }

  paymobPaymentKey(data: any){
    return this.httpClient.post('https://accept.paymobsolutions.com/api/acceptance/payment_keys', { 
      auth_token: data.auth_token, 
      amount_cents: data.amount_cents, 
      expiration: data.expiration, 
      order_id: data.order_id, 
      billing_data: data.billing_data, 
      currency: data.currency, 
      integration_id: data.integration_id,
      lock_order_when_paid: data.lock_order_when_paid
    });
  }

  paymobPaymentFrame(token: any){
    return this.httpClient.get(`https://accept.paymobsolutions.com/api/acceptance/iframes/13895?payment_token=${token}`);
  }

  updateUserSubscription(data: any){
    return this.httpClient.post(this.baseUrl + `/updateUserSubscription`, { data }, {headers: createAuthorizationHeader()});
  }

  getUserCourses(){
    return this.httpClient.post(this.baseUrl + '/getUserCourses',{userId: localStorage.getItem('Id')});
  }
}

