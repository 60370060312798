import { Component, OnInit, ViewChild } from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService } from '../shared/services/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  message = '';
  ifAdmin = false;
  showChangePassPopup = false;
  user=  {
    user_email: '',
    user_pass: ''
  }
  showPass = false;
  id;
  token;
  displayModal1 = false;
  loader = false;
  isdemo = false;
  displaySubscription = false;
  constructor(private authService: AuthService , private route:ActivatedRoute,private router:Router ) { }

  ngOnInit(): void {
    if(!localStorage.getItem("token")){
    }
    else{
      var ifAdmin = JSON.parse(localStorage.getItem("ifAdmin"));
      if(ifAdmin == true){
        this.router.navigate(['/admin/home']);
      }
      else{
        this.router.navigate(['/dashboard/welcome']);
      }
    }
  }

  login(): void{
    sessionStorage.clear()
    this.isdemo = false;
    this.loader = true;
    this.authService.login(this.user).subscribe(async (res: any) => {
      this.loader = false;

      if (res.data.noSubscriptions) {
        this.displaySubscription = true;
      } else {
        if(res.status && res.data.popupStatus == 0){
          this.token = res.data.token;
          this.id = res.data.ID;
          this.ifAdmin = res.data.ifAdmin;
          localStorage.setItem("email", res.data.Email);
          localStorage.setItem("UserName", res.data.DisplayName)
          localStorage.setItem('Id', this.id);
          this.router.navigate(['/authentication']);
          this.displayModal1 = true;
        }
        else{
          if(res.data.popupStatus == 1){
            this.showChangePassPopup = true;
          }
          else{
          this.message = "Please Write Correct Username and Pass";
          }
        }
      }
    });
  }

  getUserDetails(): any{
    this.authService.getuserdetails().subscribe((res: any) => {
      if(res.status){
        localStorage.setItem("UserName", res.user.displayName)
      }
    else{
    }
  });
}

  gotoHomeScreen(): any{
    localStorage.setItem("token", this.token);
    localStorage.setItem('Id', this.id);
    if(this.ifAdmin == true){
      this.router.navigate(['/admin/home']);
    }
    else{
      this.router.navigate(['/dashboard/welcome']);
    }


  }

  getUserSubscriptions() {
    return new Promise((resolve: any) => {
      this.authService.getUserSubscription().subscribe((res: any) => {
        resolve(res.subscriptions)
      })
    })
  }

  demoLogin(): any{
    this.isdemo = true;
    console.log("Demo_login");
    this.authService.generateDemoToken().subscribe((res: any) => {
      console.log(res);
      if(res.status){
        this.token = res.token;
        this.id = -1;
        this.ifAdmin = false;
        localStorage.setItem("email", 'demo@gmail.com');
        localStorage.setItem("UserName", 'Demo')
        localStorage.setItem('Id', this.id);
        // this.router.navigate(['/authentication']);
        this.displayModal1 = true;
    }
    })
  }
}
