<div class="chat-root">
    <div class="chat-parent">
        <!-- contact -list wrapper -->
            <div class="contact-list-wrapper">
                <div class="list-header">
                    <h4>All Chats</h4>
                </div>
                <div class="search-contact-wrapper">
                        <span class="p-input-icon-right">
                             <i class="pi pi-search"></i>
                             <input type="text" pInputText [(ngModel)]="search"  (input)="onSearchChange($event)" placeholder="Search">
                        </span>
                </div>
                <div class="contact-box-wrapper">
                    <!-- contact box to select for chatting -->
                    <div (click)="getNewChat(item)" class="contact-box" [ngClass] = "item.Recentmsg?'contact-box-active':'contact-box'" *ngFor="let item of chats">
                        <div class="contact-avatar-wrapper">
                            <div class="avatar">
                                <img src="{{item.profilePicture}}" alt="">
                                <i class="fa fa-circle"  ></i>
                            </div>
                        </div>
                        <div class="contact-name-and-message">
                            <div class="contact-name">
                                <h3>{{item.name}}</h3>
                            </div>
                            <div class="contact-message">
                                <h5 *ngIf="item.latestType == 'text'" [innerHTML]="item.latestMessage"></h5>
                                <h5 *ngIf="item.latestType == 'audio'">Audio</h5>
                                <h5 *ngIf="item.latestType == 'file'">File</h5>
                            </div>
                        </div>
                        <div class="msg-date-wrapper">
                                <h6>{{item.latestDate | date: 'dd/MM/yyyy hh:mm a'}}</h6>
                                <div *ngIf="item.latestMessages > 0" class="msg-notify-badge-wrapper">
                                    <p-badge [value]="item.latestMessages" severity="success" styleClass="mr-2"></p-badge>
                                </div>
                        </div>


                    </div>
                    <!-- ends here -->

                </div>

            </div>
            <div class="messages-wrapper">
                <div class="messages-header">
                    <h3>{{name}}</h3>
                </div>
                <div class="chat-area-wrapper" #scrollMe>
                    <ng-container  *ngFor="let message of messages">
                        <div class="reciever_wrapper chat_text" *ngIf="message.userId != userId && message.type == 'text'" [innerHTML]="message.message"></div>
                        <div class="reciever_wrapper chat_text" *ngIf="message.userId != userId && message.type == 'file'" >
                          <div style="display: flex; align-items: center; font-weight: bold;">
                            <i class="pi pi-paperclip" style="margin-right: 7px;"></i>
                            <a style="text-decoration: none; color: #7B8793;" pTooltip="View Document" href="{{message.message}}" target="_blank">File</a>
                          </div>
                        </div>
                        <div class="reciever_wrapper chat_text" *ngIf="message.userId != userId && message.type == 'audio'" >
                          <div style="display: flex; align-items: center; font-weight: bold;">
                            <i class="fa fa-microphone" style="margin-right: 7px; font-size: 16px;"></i>
                            <a style="text-decoration: none; color: #7B8793;" pTooltip="Play Audio" href="{{message.message}}" target="_blank">Audio</a>
                          </div>
                        </div>
                        <div class="chat_text sender_wrapper" *ngIf="message.userId == userId && message.type == 'text'" [innerHTML]="message.message"></div>
                        <div class="chat_text sender_wrapper" *ngIf="message.userId == userId && message.type == 'file'" >
                          <div style="display: flex; align-items: center; font-weight: bold;">
                            <i class="pi pi-paperclip" style="margin-right: 7px;"></i>
                            <a style="text-decoration: none; color: white;" pTooltip="View Document" href="{{message.message}}" target="_blank">File</a>
                          </div>
                        </div>
                        <div class="chat_text sender_wrapper" *ngIf="message.userId == userId && message.type == 'audio'" >
                          <div style="display: flex; align-items: center; font-weight: bold;">
                            <i class="fa fa-microphone" style="margin-right: 7px; font-size: 16px;"></i>
                            <a style="text-decoration: none; color: white;" pTooltip="Play Audio" href="{{message.message}}" target="_blank">Audio</a>
                          </div>
                        </div>
                    </ng-container>
                </div>
                <div class="currently-typing-wrapper">

                </div>
                <div class="input-area-wrapper">
                    <div class="input-wrapper">
                      <textarea rows="1" cols="1" id="text_chat" [(ngModel)]="userMessage" placeholder="Enter your message here" (keydown.enter)="sendNewMessage()"></textarea>
                    </div>
                    <div class="send-btn-wrapper">
                      <input type='file' id="getdoc" style="display:none" accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx" (change)="onFileUpload($event)">
                      <button class="mr-2" onclick="document.getElementById('getdoc').click()">
                        <i class="pi pi-paperclip"></i>
                      </button>
                      <button class="mr-2" *ngIf="!recording" (click)="initiateRecording()">
                        <i class="fa fa-microphone"></i>
                      </button>
                      <button class="recording mr-2" *ngIf="recording" (click)="stopRecording()">
                        <i class="fa fa-microphone"></i>
                      </button>
                      <button [disabled]="userMessage == ''" (click)="sendNewMessage()">
                        <i class="pi pi-send"></i>
                      </button>
                    </div>
                </div>

            </div>

        </div>

    </div>

