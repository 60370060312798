import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common'
import { ChatService } from 'src/app/shared/services/chat.service';
declare var $: any;
import * as RecordRTC from 'recordrtc';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import crypto from 'crypto-js'
@Component({
  selector: 'app-userchat',
  templateUrl: './userchat.component.html',
  styleUrls: ['./userchat.component.scss'],
  providers: [MessageService]
})
export class UserchatComponent implements OnInit, OnDestroy {
  demo = localStorage.getItem('Id')
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  search:any;
  filteredContacts:any;
  messages = [];
  //Lets declare Record OBJ
  record;
  //Will use this flag for toggeling recording
  recording = false;
  //URL of Blob
  url;
  error;

  recordingStream: any;

  constructor(public datepipe: DatePipe, private chatService: ChatService, private domSanitizer: DomSanitizer, private messageService: MessageService) { }
  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
    }
    /**
    * Start recording.
    */
    initiateRecording() {
    this.recording = true;
    let mediaConstraints = {
    video: false,
    audio: true
    };
    navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
    }
    /**
    * Will be called automatically.
    */
    successCallback(stream) {
      this.recordingStream = stream
      var options = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 1,
      sampleRate: 50000,
      };
      //Start Actuall Recording
      var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
      this.record = new StereoAudioRecorder(stream, options);
      this.record.record();
    }
    /**
    * Stop recording.
    */
    stopRecording() {
      this.recording = false;
      this.record.stop(this.processRecording.bind(this));
    }
    /**
    * processRecording Do what ever you want with blob
    * @param  {any} blob Blog
    */
    processRecording(blob) {
      this.url = URL.createObjectURL(blob);
      console.log("blob", blob);
      console.log("url", this.url);
      this.sendAudioFile(blob);
    }
    /**
    * Process Error.
    */
    errorCallback(error) {
      this.error = 'Can not play audio in your browser';  
    }

    stopAudio(stream: any) {
      if (stream) {
        for (let i = 0; i < stream.getTracks().length; i++) {
          stream.getTracks()[i].stop()
        }
      }
    }
    
    sendAudioFile = file => {
      this.stopAudio(this.recordingStream)
      const formData = new FormData();
      formData.append('chatDocument', new File([file], 'audio.wav', { type: 'audio/wav' }));
      this.chatService.uploadChatDocument(formData).subscribe((res: any) => {
        var date = new Date()
        var data = {
          userId: this.id,
          name: localStorage.getItem('UserName'),
          message: res.data.chatFile,
          date: date,
          type: 'audio'
        }
        this.allMessages.push(data);
        var newdata = {
          userId: JSON.parse(localStorage.getItem('Id')),
          messages: this.allMessages,
          currentUser: localStorage.getItem("Id")
        }
    
        this.chatService.sendMessage({message: res.data.chatFile, type: 'audio', roomName: JSON.parse(localStorage.getItem('Id')), userId: JSON.parse(localStorage.getItem('Id')), data: newdata}, cb => {
          console.log("ACKNOWLEDGEMENT ", cb);
        });
      })
    };
  Contacts =
  [
    {
      profilePicture:"../.././assets/images/Avatar.svg",
      Name:"John",
      Message:"hey",
      Recentmsg:true
    },
    {
      profilePicture:"../.././assets/images/Avatar.svg",
      Name:"Elan",
      Message:"heyy",
      Recentmsg:false

    },
    {
      profilePicture:"../.././assets/images/Avatar.svg",
      Name:"Emmy",
      Message:"hiy",
      Recentmsg:false

    },
    {
      profilePicture:"../.././assets/images/Avatar.svg",
      Name:"Emmyyy",
      Message:"hiy",
      Recentmsg:false

    },
     {
      profilePicture:"../.././assets/images/Avatar.svg",
      Name:"wolf",
      Message:"hiy",
      Recentmsg:false

    },
    {
      profilePicture:"../.././assets/images/Avatar.svg",
      Name:"Emy",
      Message:"hiy",
      Recentmsg:false

    }
  ]
  texts = [{message: 'Hello Umar', userId: 1},{message: 'How are you?', userId: 1},{message: 'I am fine.', userId: 3}];
  userText = ''
  id = localStorage.getItem('Id');

  newMessage: any;
  allMessages: any = []

  ngAfterViewChecked() {        
    this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;   
  } 

  ngOnInit(): void {
    this.filteredContacts = this.Contacts;
    if(window.innerWidth>750){
      document.getElementById('eup-navbar-heading').innerText = 'Chat';
    }else{
      document.getElementById('eup-navbar-heading').innerText = '';
    }
    this.chatService.startChat(JSON.parse(localStorage.getItem('Id')));
    this.getUserChat();
    this.chatService.shareData()

    setTimeout(() => {
      document.getElementById('text_chat').addEventListener('keypress', function (e) {
        if (e.keyCode === 13 && !e.shiftKey) {
          e.preventDefault();
          return false;
        }
      })
    }, 100)
  }
  onFileUpload(event:any){
    const formData = new FormData();
    if (event.target.files[0].size <= 25000000) {
      formData.append('chatDocument', event.target.files[0]);
      this.chatService.uploadChatDocument(formData).subscribe((res: any) => {
        var date = new Date()
        var data = {
          userId: this.id,
          name: localStorage.getItem('UserName'),
          message: res.data.chatFile,
          date: date,
          type: 'file'
        }
        this.allMessages.push(data);
        var newdata = {
          userId: JSON.parse(localStorage.getItem('Id')),
          messages: this.allMessages,
          currentUser: localStorage.getItem("Id")
        }
    
        this.chatService.sendMessage({message: res.data.chatFile, type: 'file', roomName: JSON.parse(localStorage.getItem('Id')), userId: JSON.parse(localStorage.getItem('Id')), data: newdata}, cb => {
          console.log("ACKNOWLEDGEMENT ", cb);
        });
      })
    }
    else {
      this.messageService.add({severity:'error', summary:'File Uploading Failed', detail:'The max file upload limit is 25 mbs'});
    }
  }
  getUserChat(){
    this.chatService.getUserChat().subscribe((res: any) => {
      if (res.data.status) {
        res.data.chat.Messages = res.data.chat.Messages.replace(/[\\]/g, '').replace(/[\t]/g, '').replace(/[\n]/g, '').replace(/[\r]/g, '')
        this.messages = JSON.parse(res.data.chat.Messages)
        this.allMessages = JSON.parse(JSON.stringify(this.messages))
      }
      this.chatService.getNewMessage().subscribe((data: any) => {
        if (data.message != '' && data.message != null && sessionStorage.getItem('Sender') && data.roomName == localStorage.getItem('Id')) {
          var bytes  = crypto.AES.decrypt(data.message, 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3');
          data.message = bytes.toString(crypto.enc.Utf8);
          this.messages.push({
            'userId': sessionStorage.getItem('Sender'),
            'name': localStorage.getItem('UserName'),
            'message': data.message,
            'date': new Date().toISOString(),
            'type': data.type
          })
          this.allMessages = JSON.parse(JSON.stringify(this.messages))
        }
      })
    })
  }

  onSearchChange(event: any) {
    this.filteredContacts = this.Contacts;

    this.filteredContacts = this.filteredContacts.filter((item)=>{
      return item.Name.toLowerCase().includes(event.target.value.toLowerCase())

    })


  }
  sendChat(){
    var date = new Date()
    var data = {
      userId: this.id,
      name: localStorage.getItem('UserName'),
      message: this.userText,
      date: date,
      type: 'text'
    }
    this.allMessages.push(data);
    var newdata = {
      userId: JSON.parse(localStorage.getItem('Id')),
      messages: this.allMessages,
      currentUser: localStorage.getItem("Id")
    }

    this.chatService.sendMessage({message: this.userText, type: 'text', roomName: JSON.parse(localStorage.getItem('Id')), userId: JSON.parse(localStorage.getItem('Id')), data: newdata}, cb => {
      console.log("ACKNOWLEDGEMENT ", cb);
    });
    this.userText = '';
  }

  ngOnDestroy(): void {
    this.chatService.closeChat()
    this.chatService.closeShareData()
    this.stopAudio(this.recordingStream)
  }
}
