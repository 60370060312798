import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminService } from '../shared/services/admin.service';
import { ChatService } from '../shared/services/chat.service';
declare var $: any;
import * as RecordRTC from 'recordrtc';
import { Subscription } from 'rxjs';
import crypto from 'crypto-js'

@Component({
  selector: 'app-chat-module',
  templateUrl: './chat-module.component.html',
  styleUrls: ['./chat-module.component.scss']
})
export class ChatModuleComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  search:any;
  filteredContacts:any;
   //Lets declare Record OBJ
  record;
  //Will use this flag for toggeling recording
  recording = false;
  //URL of Blob
  url;
  error;

  recordingStream: any;

  constructor(public datepipe: DatePipe,private adminService: AdminService, private chatService: ChatService, private domSanitizer: DomSanitizer) { }
  /**
    * Start recording.
    */
   initiateRecording() {
    this.recording = true;
    let mediaConstraints = {
    video: false,
    audio: true
    };
    navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
    }
    /**
    * Will be called automatically.
    */
    successCallback(stream) {
      this.recordingStream = stream
      var options = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 1,
      sampleRate: 50000,
      };
      //Start Actuall Recording
      var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
      this.record = new StereoAudioRecorder(stream, options);
      this.record.record();
    }
    /**
    * Stop recording.
    */
    stopRecording() {
      this.recording = false;
      this.record.stop(this.processRecording.bind(this));
    }
    /**
    * processRecording Do what ever you want with blob
    * @param  {any} blob Blog
    */
    processRecording(blob) {
      this.url = URL.createObjectURL(blob);
      console.log("blob", blob);
      console.log("url", this.url);
      this.sendAudioFile(blob);
    }
    /**
    * Process Error.
    */
    errorCallback(error) {
      this.error = 'Can not play audio in your browser';
    }

    stopAudio(stream: any) {
      if (stream) {
        for (let i = 0; i < stream.getTracks().length; i++) {
          stream.getTracks()[i].stop()
        }
      }
    }
    
    sendAudioFile = file => {
      this.stopAudio(this.recordingStream)
      const formData = new FormData();
      formData.append('chatDocument', new File([file], 'audio.wav', { type: 'audio/wav' }));
      this.chatService.uploadChatDocument(formData).subscribe((res: any) => {
        var date = new Date()
        var data = {
          userId: localStorage.getItem("Id"),
          name: localStorage.getItem('UserName'),
          message: res.data.chatFile,
          date: date,
          type: 'audio'
        }
        this.allMessages.push(data);
        var newdata = {
          userId: this.chatId,
          messages: this.allMessages,
          currentUser: localStorage.getItem("Id")
        }

        this.chatService.sendMessage({message: res.data.chatFile, type: 'audio', roomName: this.chatId, userId: localStorage.getItem("Id"), data: newdata}, cb => {
          console.log("ACKNOWLEDGEMENT ", cb);
        });
      })
    };
  Contacts =
  [
    {
      profilePicture:"../.././assets/images/Avatar.svg",
      Name:"John",
      Message:"hey",
      Recentmsg:true
    },
    {
      profilePicture:"../.././assets/images/Avatar.svg",
      Name:"Elan",
      Message:"heyy",
      Recentmsg:false

    },
    {
      profilePicture:"../.././assets/images/Avatar.svg",
      Name:"Emmy",
      Message:"hiy",
      Recentmsg:false

    },
    {
      profilePicture:"../.././assets/images/Avatar.svg",
      Name:"Emmyyy",
      Message:"hiy",
      Recentmsg:false

    },
     {
      profilePicture:"../.././assets/images/Avatar.svg",
      Name:"wolf",
      Message:"hiy",
      Recentmsg:false

    },
    {
      profilePicture:"../.././assets/images/Avatar.svg",
      Name:"Emy",
      Message:"hiy",
      Recentmsg:false

    }
  ]
  userMessage = ''
  texts = [{message: 'Hello Umar', userId: 1},{message: 'How are you?', userId: 1},{message: 'I am fine.', userId: 2}]
  userId: any;
  chats: any;
  messages = []
  name = 'user';
  chatId: any;
  newMessage: any;
  firstUser = false

  allMessages: any = []

  tempChats: any = []

  ngAfterViewChecked() {        
    this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;   
  } 

  ngOnInit(): void {
    this.userId = localStorage.getItem('Id');
    this.filteredContacts = this.Contacts;
    this.getAllChats();
    this.chatService.shareData()
    this.getIntervalChats()

    setTimeout(() => {
      document.getElementById('text_chat').addEventListener('keypress', function (e) {
        if (e.keyCode === 13 && !e.shiftKey) {
          e.preventDefault();
          return false;
        }
      })
    }, 100)
  }

  getIntervalChats() {
    this.chatService.getShareMessage().subscribe(async (data: any) => {
      if (data) {
        await this.updateUnreadMessages()
        this.subscriptions.push(
          this.adminService.getAllChats(localStorage.getItem('Id')).subscribe((res: any) => {
            for (let i = 0; i < res.data.chats.length; i++) {
              for (let j = 0; j < this.chats.length; j++) {
                if (res.data.chats[i].UserID == this.chats[j].UserID) {
                  this.chats[j].unreadMessages = JSON.parse(res.data.chats[i].Messages)
                  let messages = JSON.parse(res.data.chats[i].Messages)
                  var length = messages.length - 1;
                  this.chats[j].latestMessage = messages[length].message;
                  this.chats[j].latestType =  messages[length].type;
                  this.chats[j].latestDate =  messages[length].date;
                }
              }
            }
    
            res.data.chats.splice(res.data.chats.findIndex((item: any) => item.UserID == this.chatId), 1)
    
            for (let i = 0; i < res.data.chats.length; i++) {
              for (let j = 0; j < this.chats.length; j++) {
                if (res.data.chats[i].UserID == this.chats[j].UserID) {
                  if (JSON.parse(res.data.chats[i].Messages).length - this.chats[j].Messages.length > 0) {
                    this.chats[j].latestMessages = JSON.parse(res.data.chats[i].Messages).length - this.chats[j].Messages.length
                  }
                  break;
                }
              }
            }
          })
        )
      }
    })
  }

  getAllChats(){
    this.adminService.getAllChats(localStorage.getItem('Id')).subscribe((res: any) => {
      var chats = res.data.chats;
      for(let i=0; i<chats.length; i++){
        chats[i].profilePicture = '../.././assets/images/default.jpeg';
        chats[i].Messages = chats[i].Messages.replace(/[\\]/g, '').replace(/[\t]/g, '').replace(/[\n]/g, '').replace(/[\r]/g, '')
        chats[i].Messages = JSON.parse(chats[i].Messages)
        var length = chats[i].Messages.length-1;
        chats[i].latestMessage =  chats[i].Messages[length].message;
        chats[i].latestType =  chats[i].Messages[length].type;
        chats[i].latestDate =  chats[i].Messages[length].date;
        chats[i].name = chats[i].Messages[0].name;
        chats[i].latestMessages = chats[i].UnreadMessages
        chats[i].unreadMessages = chats[i].Messages
      }
      this.chats = chats;
      this.tempChats = JSON.parse(JSON.stringify(this.chats))
      if (this.chats.length > 0) {
        this.getNewChat(chats[0])
      }
    })
  }

  onSearchChange(event: any) {
    this.chats = this.tempChats.filter((item)=>{
      return item.name.toLowerCase().includes(event.target.value.toLowerCase())
    })
  }

  async getNewChat(user: any){
    if (this.firstUser) {
      this.chatService.closeChat()
    }
    user.latestMessages = 0
    user.Messages = JSON.parse(JSON.stringify(user.unreadMessages))
    this.messages = user.Messages
    this.name = user.Messages[0].name;
    this.chatId = user.UserID
    this.chatService.startChat(this.chatId)
    this.getUserChat();
    await this.updateUnreadMessages()
    this.firstUser = true
  }

  updateUnreadMessages() {
    return new Promise((resolve, reject) => {
      let data = {
        "userId": this.chatId
      }
      this.chatService.updateUnreadMessages(data).subscribe((res: any) => {
        resolve(res)
      })
    })
  }

  getUserChat(){
    this.allMessages = JSON.parse(JSON.stringify(this.messages))
    this.chatService.getNewMessage().subscribe((data: any) => {
      if (this.chatId != sessionStorage.getItem('Sender') && sessionStorage.getItem('Sender') != localStorage.getItem('Id') && data.message != '' && data.message != null) {
        var bytes  = crypto.AES.decrypt(data.message, 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3');
        data.message = bytes.toString(crypto.enc.Utf8);
        this.chats[this.chats.findIndex((item: any) => item.UserID == sessionStorage.getItem('Sender'))].Messages.push({
          'userId': sessionStorage.getItem('Sender'),
          'name': localStorage.getItem('UserName'),
          'message': data.message,
          'date': new Date().toISOString(),
          'type': data.type
        })
        let index = this.chats.findIndex((item: any) => item.UserID == sessionStorage.getItem('Sender'))
        var length = this.chats[index].Messages.length-1;
        this.chats[index].latestMessage =  this.chats[index].Messages[length].message;
        this.chats[index].latestType =  this.chats[index].Messages[length].type;
        this.chats[index].latestDate =  this.chats[index].Messages[length].date;
      }
      else {
        if (data.message != '' && data.message != null && sessionStorage.getItem('Sender')) {
          var bytes  = crypto.AES.decrypt(data.message, 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3');
          data.message = bytes.toString(crypto.enc.Utf8);
          this.messages.push({
            'userId': sessionStorage.getItem('Sender'),
            'name': localStorage.getItem('UserName'),
            'message': data.message,
            'date': new Date().toISOString(),
            'type': data.type
          })
          if (sessionStorage.getItem('Sender') != localStorage.getItem('Id')) {
            let index = this.chats.findIndex((item: any) => item.UserID == sessionStorage.getItem('Sender'))
            var length = this.messages.length - 1
            this.chats[index].latestMessage =  this.messages[length].message;
            this.chats[index].latestType =  this.messages[length].type;
            this.chats[index].latestDate =  this.messages[length].date;
          }
          this.allMessages = JSON.parse(JSON.stringify(this.messages))
        }
      }
    })
  }

  sendNewMessage(){
    var date = new Date()
    var data = {
      userId: localStorage.getItem("Id"),
      name: localStorage.getItem('UserName'),
      message: this.userMessage,
      type: 'text',
      date: date
    }
    this.allMessages.push(data);
    var newdata = {
      userId: this.chatId,
      messages: this.allMessages,
      currentUser: localStorage.getItem("Id")
    }

    this.chatService.sendMessage({message: this.userMessage, type: 'text', roomName: this.chatId, userId: localStorage.getItem("Id"), data: newdata}, cb => {
      console.log("ACKNOWLEDGEMENT ", cb);
    });
    this.userMessage = '';
  }
  onFileUpload(event:any){
    const formData = new FormData();
    formData.append('chatDocument', event.target.files[0]);
    this.chatService.uploadChatDocument(formData).subscribe((res: any) => {
      var date = new Date()
      var data = {
        userId: localStorage.getItem("Id"),
        name: localStorage.getItem('UserName'),
        message: res.data.chatFile,
        date: date,
        type: 'file'
      }
      this.allMessages.push(data);
      var newdata = {
        userId: this.chatId,
        messages: this.allMessages,
        currentUser: localStorage.getItem("Id")
      }

      this.chatService.sendMessage({message: res.data.chatFile, type: 'file', roomName: this.chatId, userId: localStorage.getItem("Id"), data: newdata}, cb => {
        console.log("ACKNOWLEDGEMENT ", cb);
      });
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
    this.chatService.closeChat()
    this.chatService.closeShareData()
    this.stopAudio(this.recordingStream)
  }

}
